@import url(https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Hurricane&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Satisfy&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Caveat&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Vujahday+Script&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Homemade+Apple&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Cedarville+Cursive&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap);
.static-page {
  height: 100%;
  border-radius: 4px;
  background-color: rgb(255, 255, 255);
  box-shadow: 1.5px 2.598px 4px 0px rgba(0, 28, 61, 0.01);
  padding: 45px 40px 32px;
  margin: 40px 40px;
  position: relative;
}

.static-page h1, .static-page h2, .static-page h3, .static-page h4 {
  font-family: 'Roboto', sans-serif;
  letter-spacing: 0.5px;
  padding: 0px 0px 0px;
  margin: 20px 0px 20px;
}

.static-page h1 {
  font-size: 18px;
  font-weight: 500;
  color: #344750;
}

.static-page h2 {
  font-size: 24px;
  font-weight: 600;
  color: #112268;
}

.static-page h3 {
  font-size: 24px;
  font-weight: 500;
  color: #112268;
}

.static-page h3 span {
  font-weight: 600;
  color: #21c4d3;
}

.static-page h4 {
  font-size: 14px;
  font-weight: 600;
  color: #344750;
}

.static-page p {
  font-size: 15px;
  color: #677982;
  font-weight: 400;
  text-align: left;
  letter-spacing: 0px;
  /*margin: 0px 0px 0px 0px;*/
  margin: 0px 0px 20px;
  line-height: 18px;
  word-break: break-all;
}

.static-page p a {
  color: #112268;
}

.static-page ol {
  width: auto;
  padding: 0px;
  margin: 0px 0px 0px 30px;
}

.static-page ol li {
  font-family: 'Roboto', sans-serif;
  font-size: 14px;
  color: #2b3f57;
  font-weight: 400;
  text-align: left;
  letter-spacing: 0px;
  margin: 0px 0px 0px 0px;
  padding: 0px !important;
  line-height: 30px;
}

.static-page ul {
  padding: 0px;
  margin: 15px 0px 30px 30px;
}

.static-page ul li {
  font-size: 14px;
  color: #2b3f57;
  font-weight: 400;
  text-align: left;
  letter-spacing: 0px;
  margin: 0px 0px 0px 0px;
  padding: 0px !important;
  line-height: 30px;
}

.add-to-home-screen-box {
  width: 90%;
  margin: 0px auto;
  border-radius: 6px;
  background: #ffffff;
  padding: 6px 12px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
  position: fixed;
  bottom: 30px;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  display: flex;
  align-items: center;
  z-index: 999;
}

.add-to-home-screen-box h1{
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 14px;
  font-weight: 400;
  color: #344750;
}

.add-to-home-screen-box h1 .share-icon {
  width: 18px;
  height: 18px;
  display: inline-block;
  margin: 2px 2px 0px;
  vertical-align: sub;
}

.add-to-home-screen-box h1 .share-icon svg{
  width: 100%;
  height: 100%;
}

.add-to-home-screen-box .close-icon {
  display: flex;
  align-items: center;
  position: relative;
  top: 1px;
  margin-left: 5px;
  margin-left: auto;
}

.add-to-home-screen-box .add-icon {
  background-color: #7f8c8c;
  border-radius: 4px;
  padding: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  min-width: 24px;
  min-height: 24px;
  margin-right: 8px;
}

.add-to-home-screen-box .add-icon svg{
  width: 100%;
  height: 100%;
}

.add-to-home-screen-box::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -10px;
  border-width: 10px;
  border-style: solid;
  border-color: white transparent transparent transparent;
}

.install-app-warraper {
  position: fixed;
  bottom: 30px;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  z-index: 999;
}

.install-app-warraper .install-app-btn {
  background-color: var(--primary);
  border: 1px solid var(--primary);
  color: #fff;
  width: auto;
  min-width: 145px;
  height: 40px;
  border-radius: 30px;
  font-size: 16px;
}

.install-app-warraper .install-app-btn .MuiButton-label {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.install-app-warraper .close-icon {
  color: #fff;
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  right: 16px;
  margin-top: 3px;
} 

.install-app-warraper .close-icon .icon {
  font-size: 14px;
}
@charset "UTF-8";
@font-face {
  font-family: "dashboard-icons";
  src: url(/static/media/dashboard-icons.a2f55daa.eot);
  src: url(/static/media/dashboard-icons.a2f55daa.eot?#iefix) format("embedded-opentype"), url(/static/media/dashboard-icons.0d2ddb06.woff) format("woff"), url(/static/media/dashboard-icons.ceb5532f.ttf) format("truetype"), url(/static/media/dashboard-icons.8a9a003b.svg#untitled-font-16) format("svg");
  font-weight: normal;
  font-style: normal;
}

[data-dashboardicon]:before {
  font-family: "dashboard-icons"!important;
  content: attr(data-dashboardicon);
  font-style: normal !important;
  font-weight: normal !important;
  -webkit-font-feature-settings: normal !important;
          font-feature-settings: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

[class^="dashboardicon-"]:before, [class*=" dashboardicon-"]:before {
  font-family: "dashboard-icons"!important;
  font-style: normal !important;
  font-weight: normal !important;
  -webkit-font-feature-settings: normal !important;
          font-feature-settings: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.dashboardicon-view:before {
  content: "\63";
}
.dashboardicon-pdf:before {
  content: "\64";
}
.dashboardicon-register:before {
  content: "\65";
}
.dashboardicon-social-wall:before {
  content: "\61";
}
.dashboardicon-warning:before {
  content: "\62";
}
.dashboardicon-unlink:before {
  content: "\66";
}
.dashboardicon-flush:before {
  content: "\67";
}
.dashboardicon-edit:before {
  content: "\68";
}
.dashboardicon-edit-1:before {
  content: "\69";
}
.dashboardicon-attachment:before {
  content: "\6a";
}
.dashboardicon-action:before {
  content: "\6b";
}
.dashboardicon-add:before {
  content: "\6c";
}
.dashboardicon-admin:before {
  content: "\6d";
}
.dashboardicon-back:before {
  content: "\6e";
}
.dashboardicon-back-2:before {
  content: "\6f";
}
.dashboardicon-maintenance-request:before {
  content: "\70";
}
.dashboardicon-calendar:before {
  content: "\71";
}
.dashboardicon-calendar-1:before {
  content: "\72";
}
.dashboardicon-card:before {
  content: "\73";
}
.dashboardicon-change-password:before {
  content: "\74";
}
.dashboardicon-check-status:before {
  content: "\75";
}
.dashboardicon-checking:before {
  content: "\76";
}
.dashboardicon-left:before {
  content: "\77";
}
.dashboardicon-invoice:before {
  content: "\78";
}
.dashboardicon-information:before {
  content: "\79";
}
.dashboardicon-grid-view:before {
  content: "\7a";
}
.dashboardicon-down:before {
  content: "\41";
}
.dashboardicon-down-1:before {
  content: "\42";
}
.dashboardicon-delete:before {
  content: "\43";
}
.dashboardicon-customer-list:before {
  content: "\44";
}
.dashboardicon-conatct:before {
  content: "\45";
}
.dashboardicon-left-1:before {
  content: "\46";
}
.dashboardicon-list-view:before {
  content: "\47";
}
.dashboardicon-archive:before {
  content: "\48";
}
.dashboardicon-mail:before {
  content: "\49";
}
.dashboardicon-minus:before {
  content: "\4a";
}
.dashboardicon-notification-list:before {
  content: "\4b";
}
.dashboardicon-pagination-1:before {
  content: "\4c";
}
.dashboardicon-pagination-2:before {
  content: "\4d";
}
.dashboardicon-payment-status:before {
  content: "\4e";
}
.dashboardicon-personal-info:before {
  content: "\4f";
}
.dashboardicon-plantlocations:before {
  content: "\50";
}
.dashboardicon-policies:before {
  content: "\51";
}
.dashboardicon-profile-dot:before {
  content: "\52";
}
.dashboardicon-programs:before {
  content: "\53";
}
.dashboardicon-purchaseorder:before {
  content: "\54";
}
.dashboardicon-queues:before {
  content: "\55";
}
.dashboardicon-quotes:before {
  content: "\56";
}
.dashboardicon-refresh:before {
  content: "\57";
}
.dashboardicon-reset:before {
  content: "\58";
}
.dashboardicon-reverse:before {
  content: "\59";
}
.dashboardicon-right:before {
  content: "\5a";
}
.dashboardicon-right-1:before {
  content: "\30";
}
.dashboardicon-role:before {
  content: "\31";
}
.dashboardicon-search:before {
  content: "\32";
}
.dashboardicon-send:before {
  content: "\33";
}
.dashboardicon-setting:before {
  content: "\34";
}
.dashboardicon-upload:before {
  content: "\35";
}
.dashboardicon-up:before {
  content: "\36";
}
.dashboardicon-up-1:before {
  content: "\37";
}
.dashboardicon-unit-id:before {
  content: "\38";
}
.dashboardicon-translation:before {
  content: "\39";
}
.dashboardicon-timesheet:before {
  content: "\21";
}
.dashboardicon-status:before {
  content: "\22";
}
.dashboardicon-filter-up:before {
  content: "\23";
}
.dashboardicon-filter-down:before {
  content: "\24";
}
.dashboardicon-filter:before {
  content: "\25";
}
.dashboardicon-settings:before {
  content: "\26";
}
.dashboardicon-lease-charge-details:before {
  content: "\27";
}
.dashboardicon-attributes:before {
  content: "\28";
}
.dashboardicon-download:before {
  content: "\29";
}
.dashboardicon-milestone:before {
  content: "\2a";
}
.dashboardicon-logout:before {
  content: "\2b";
}
.dashboardicon-import:before {
  content: "\2c";
}
.dashboardicon-download-1:before {
  content: "\2d";
}
.dashboardicon-upload-1:before {
  content: "\2e";
}
.dashboardicon-publish:before {
  content: "\2f";
}
.dashboardicon-dashboard:before {
  content: "\3a";
}
.dashboardicon-closed-programs:before {
  content: "\3b";
}
.dashboardicon-open-programs:before {
  content: "\3c";
}
.dashboardicon-po-count:before {
  content: "\3d";
}
.dashboardicon-total-quotes:before {
  content: "\3e";
}
.dashboardicon-total-amount:before {
  content: "\3f";
}
.dashboardicon-open-order:before {
  content: "\40";
}
.dashboardicon-closed-order:before {
  content: "\5b";
}
.dashboardicon-pmslog:before {
  content: "\5d";
}
.dashboardicon-labor:before {
  content: "\5e";
}
.dashboardicon-labor-1:before {
  content: "\5f";
}
.dashboardicon-disable-icon:before {
  content: "\60";
}
.dashboardicon-log-icon:before {
  content: "\7b";
}
.dashboardicon-portal:before {
  content: "\7c";
}
.dashboardicon-commercial:before {
  content: "\7d";
}
.dashboardicon-corporate:before {
  content: "\7e";
}
.dashboardicon-add-document-1:before {
  content: "\5c";
}
.dashboardicon-add-parcel:before {
  content: "\e000";
}
.dashboardicon-add-payment:before {
  content: "\e001";
}
.dashboardicon-subscription-panel:before {
  content: "\e002";
}
.dashboardicon-unsubscribe:before {
  content: "\e003";
}
.dashboardicon-resident:before {
  content: "\e004";
}
.dashboardicon-autopay-log:before {
  content: "\e005";
}
.dashboardicon-email-manage:before {
  content: "\e006";
}
.dashboardicon-mail-log:before {
  content: "\e007";
}
.dashboardicon-image:before {
  content: "\e008";
}
.dashboardicon-guest-card:before {
  content: "\e009";
}
.dashboardicon-close:before {
  content: "\e00a";
}
.dashboardicon-payment-log:before {
  content: "\e00b";
}
.dashboardicon-add-charges:before {
  content: "\e00c";
}
.dashboardicon-transactions:before {
  content: "\e00d";
}
.dashboardicon-compose-message:before {
  content: "\e00e";
}
.dashboardicon-contact-information:before {
  content: "\e010";
}
.dashboardicon-personal-information:before {
  content: "\e011";
}
.dashboardicon-add-charges-1:before {
  content: "\e012";
}
.dashboardicon-add-payment-1:before {
  content: "\e00f";
}
.dashboardicon-history:before {
  content: "\e013";
}
.dashboardicon-website-view:before {
  content: "\e014";
}
.dashboardicon-users:before {
  content: "\e015";
}
.dashboardicon-unit-icon:before {
  content: "\e016";
}
.dashboardicon-document:before {
  content: "\e017";
}
.dashboardicon-current-application:before {
  content: "\e018";
}
.dashboardicon-image-search:before {
  content: "\e019";
}
.Login_login-main__3illx {
  width: 400px;
  text-align: center;
}

.Login_login-main__3illx .MuiContainer-root {
  background-color: #fff;
  border-radius: 4px;
  box-shadow: 0px 2px 0.44px 0.36px rgba(0, 0, 0, 0.05);
  padding: 30px;
}

.Login_login-wrap__2qq1v {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 95vh;
}

.Login_login-logo__Z4sRM {
  width: 168px;
  margin: 30px auto;
}

.Login_login-logo__Z4sRM img {
  object-fit: contain;
  object-position: center;
  width: 100%;
  min-height: 70px;
  max-height: 120px;
  height: auto;
  /* height:120px; */
}

.Login_login-main__3illx h1 {
  font-size: 20px;
  padding-bottom: 30px;
  color: rgba(0, 0, 0, 0.87);
}
.Login_login-main__3illx h1:hover {
  text-decoration: none;
}

.Login_login-main__3illx .form-wrap .MuiFormControl-root {
  margin-bottom: 15px;
}

.Login_login-with-social__hMiU1 {
  /* padding-top: 30px; */
  margin-top: 30px;
  /* border-top: 1px solid #e7ecf2; */
  position: relative;
}

.Login_login-with-social__hMiU1 .MuiGrid-item {
  width: auto;
  float: left;
  display: contents;
}

/* 
.login-with-social::before {
  background-color: #fff;
  font-size: 14px;
  color: #7f878e;
  content: "or";
  display: block;
  clear: both;
  width: 20px;
  position: absolute;
  top: -12px;
  left: 50%;
  margin-left: -10px;
} */

.MuiGrid-root .Login_login-with-google__2NXVq {
  background-color: #4285f4;
  border: 1px solid #2d7df5;
  padding: 6px 15px 6px 40px;
  width: 170px;
  margin: 0 auto;
  position: relative;
  font-family: "Source Sans Pro", sans-serif;
  font-size: 13px;
  color: #fff;
  font-weight: 400;
  text-align: center;
  letter-spacing: .5px;
  border-radius: 90px;
}

.MuiGrid-root .Login_login-with-google__2NXVq:hover, .MuiGrid-root .Login_login-with-google__2NXVq:focus {
  background-color: #4285f4;
  border: 1px solid #2d7df5;
}

.Login_login-with-google__2NXVq:before {
  background: url("/images/google-icon.png");
  background-repeat: no-repeat;
  background-position: 5px 6px;
  content: '';
  display: block;
  width: 18px;
  height: 18px;
  position: absolute;
  left: 3px;
  top: 2px;
  background-color: #fff;
  padding: 15px;
  border-radius: 90px;
  text-align: center;
}

.Login_login-with-facebook__1Vesk:before {
  background: url("/images/fb-icon.png");
  background-repeat: no-repeat;
  background-position: 10px 6px;
  content: '';
  display: block;
  width: 18px;
  height: 18px;
  position: absolute;
  left: 3px;
  top: 3px;
  background-color: #fff;
  padding: 15px;
  border-radius: 90px;
  text-align: center;
}

.Login_login-with-facebook__1Vesk {
  background-color: #415dae;
  border: 1px solid #415dae;
  padding: 7px 10px 8px 38px;
  width: 170px;
  margin: 0 auto;
  position: relative;
  font-family: "Source Sans Pro", sans-serif;
  font-size: 13px;
  color: #fff;
  font-weight: 400;
  text-align: center;
  letter-spacing: .5px;
  border-radius: 90px;
}

.Login_login-with-facebook__1Vesk span {
  font-size: 12px;
}

.Login_login-with-facebook__1Vesk:hover,
.Login_login-with-facebook__1Vesk:focus {
  background-color: #415dae;
  border: 1px solid #415dae;
}

.Login_forgot-password__1QuQO {
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 14px;
  font-weight: 400;
  color: var(--tertiary);
  text-align: left;
  text-decoration: none;
  float: left;
  line-height: 65px;
}

.Login_forgot-password__1QuQO:hover {
  text-decoration: none;
}

.Login_applicant-application__3NmVB {
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 14px;
  font-weight: 400;
  color: #6e8496;
  text-align: left;
  text-decoration: none;
  float: left;
}
.Login_applicant-application__3NmVB:hover {
  text-decoration: none;
}

.Login_divided-line__17FZe {
  margin-top: 15px;
  border-top:1px solid #d8dae4;
  position: relative;
}
/* .divided-line::before {
  background-color: #fff;
  font-size: 14px;
  color: #7f878e;
  content: "";
  display: block;
  clear: both;
  width: 20px;
  position: absolute;
  top: -12px;
  left: 50%;
  margin-left: -10px;
} */

.Login_last-section__1KKBr .MuiGrid-item:nth-child(1) {
  position: relative;
}
.Login_last-section__1KKBr .MuiGrid-item:nth-child(1)::after {
  content: '';
  width: 1px;
  height: 20px;
  background: #d8dae4;
  position: absolute;
  right: 0;
  bottom: -5px;
}

.Login_creat-an-acc__3atK0 {
  float: none;
  font-size: 14px;
  font-weight: 700;
  color: var(--primary);
  margin-top: 24px;
  display: block;
  padding: 5px 0px 5px;
  line-height: 0;
  width: 100%;
  text-align: center;
}

.Login_applicant-login__StPDP {
  /* float: right; */
  font-size: 14px;
  font-weight: 700;
  color: #0d3f67;
  margin-top: 24px;
  display: block;
  padding: 5px 0px 5px;
  line-height: 0;
  width: 100%;
  text-align: center;
}

.Login_password-icon__2_Rjl i {
  font-size: 20px;
  margin: 8px 0px 0px;
}

.Login_verify-email-butn__2ubUP {
  display: block;
}

.Login_login-main__3illx .MuiFormHelperText-root.Mui-error {
  position: relative;
  bottom: 0px;
}

.Login_login-with-or__nRqjB {
  margin-top: 15px;
  border-top: 1px solid #d8dae4;
  position: relative;
}

.Login_login-with-or__nRqjB::before {
  background-color: #fff;
  font-size: 14px;
  color: #7f878e;
  content: "or";
  display: block;
  clear: both;
  width: 20px;
  position: absolute;
  top: -12px;
  left: 50%;
  margin-left: -10px;
}

.Login_login-with-or__nRqjB::after {
  content: "";
  display: block;
  clear: both;
  height: auto;
}

.Login_property-ids__2sW1j .MuiInputBase-root {
  padding: 3px 0px 3px 8px !important;
}

@media only screen and (max-width: 600px) and (min-width: 320px) {
  .Login_login-main__3illx {
    width: 90%;
  }

  .Login_login-wrap__2qq1v {
    height: auto;
    margin-bottom: 30px;
  }

  .Login_login-main__3illx h1 {
    padding: 15px 0px;
  }

  .Login_login-main__3illx .MuiContainer-root {
    padding: 10px 10px 30px;
  }

  .Login_login-with-facebook__1Vesk {
    margin-bottom: 10px;
  }

  .Login_login-main__3illx .Login_mb-forgot-password__110mv {
    line-height: 65px;
    text-align: center;
    width: 100%;
  }
}
/* if allow_edit is false then disable form edit */
.Application_disable-form-edit__1SfnB {
  pointer-events: none;
}

.Application_disable-form-edit__1SfnB form,
.Application_disable-form-edit__1SfnB .Application_auto-fill__3kOA1,
.Application_disable-form-edit__1SfnB .Application_submit-butn-main__11SY0 button {
  opacity: 0.7;
}

.Application_override-disable-form-edit__1E0CJ {
  pointer-events: auto;
  opacity: 1 !important;
}

/* if allow_edit is false then disable form edit */

.Application_disabled__2kKso{
  opacity: 0.5;
}

.Application_login-logo__1jVQI {
  width: 100%;
  padding: 0 20px;
  margin: 20px 0;
  text-align: center;
}

.Application_login-logo__1jVQI img {
  object-fit: contain;
  object-position: center;
  width: 100%;
}

/*Application Form*/
.Application_application-wrap__3xmqk {
  margin: 0 auto;
  position: relative;
  background: #f2f3f7;
  min-height: 100vh;
}

.Application_application-form-header__16sV9 {
  padding: 20px;
  background-color: #f2f3f7;
  position: -webkit-sticky;
  position: sticky;
  top: 0px;
}

.Application_logo__3RUPe {
  z-index: 9;
  width: 100%;
  text-align: center;
}

.Application_application-wrap__3xmqk h2 {
  font-family: 'Roboto', sans-serif;
  font-size: 22px;
  font-weight: 400;
  color: #000;
  text-align: center;
  padding-top: 46px;
  letter-spacing: 1px;
}

.Application_application-wrap__3xmqk h1 {
  font-family: 'Roboto', sans-serif;
  font-size: 20px;
  font-weight: 500;
  color: var(--primary);
  letter-spacing: 1px;
}

.Application_application-contain-area__18x7P {
  padding: 20px 40px 40px;
}

.Application_application-container__3O2_U {
  width: 100%;
  background-color: #fff;
  border-radius: 6px;
  /* margin-top: 140px; */
}

.Application_application-container__3O2_U h1 {
  font-family: 'Roboto', sans-serif;
  font-size: 24px;
  font-weight: 600;
  color: #344750;
  text-align: center;
  margin: 0px;
  width: 100%;
}

.Application_application-container__3O2_U h4 {
  font-family: 'Roboto', sans-serif;
  font-size: 14px;
  font-weight: 400;
  color: #344750;
  text-align: center;
  padding: 0px 0px 40px;
  margin: 0px;
  width: 100%;
}

.Application_please-wait__29e25 {
  width: 100%;
  background-color: #fff;
  border-radius: 6px;
  position: absolute;
  top: 0px;
  z-index: -1;
}

.Application_please-wait-con__3j49m {
  width: 100%;
  text-align: center;
  font-family: 'Roboto', sans-serif;
  font-size: 14px;
  font-weight: 400;
  color: #344750;
}

.Application_form-height__2U59H {
  min-height: 100vh;
  margin-bottom: 70px;
}

.Application_application-form__3eMtM main {
  padding: 0px;
}

.Application_form-divider__13Z9u {
  margin-top: 30px;
}

.Application_heading__3Yg9l {
  margin-bottom: 24px;
}

.Application_sub-heading__3J6Fz {
  font-family: 'Roboto', sans-serif;
  font-size: 15px;
  color: var(--tertiary);
}

.Application_form-grp__UjuVN {
  margin: 30px 40px 0px;
}

.Application_form-grp__UjuVN h1 {
  font-family: 'Roboto', sans-serif;
  font-size: 18px;
  font-weight: 600;
  color: var(--primary);
  text-align: left;
  margin: 0px;
  background-color: transparent;
}

.Application_form-grp__UjuVN .MuiFormControl-root {
  margin-bottom: 0px;
}

.Application_spacing-bottom__2lfv3 {
  margin-bottom: 30px !important;
}

/* for adding space of spacing={3} between 2 containers */
.Application_container-spacing-3__2gadJ {
  margin-top: 12px;
}

/* Radio Button*/
.Application_radio-btn__1w0E5 .MuiFormControl-root {
  display: block;
  margin-bottom: 0px;
}
.Application_radio-btn__1w0E5 .MuiFormLabel-root {
  font-family: 'Roboto', sans-serif;
  font-size: 15px;
  color: var(--tertiary);
  padding-right: 16px;
  float: left;
  margin-top: 4px;
}
.Application_radio-btn__1w0E5 .MuiFormControlLabel-root .MuiButtonBase-root {
  padding: 0px 4px 4px 9px;
  align-self: flex-start;
  color: var(--tertiary);
}
.Application_radio-btn__1w0E5 .MuiFormControlLabel-root .MuiButtonBase-root.Mui-checked {
  color: var(--primary);
}
.Application_radio-btn__1w0E5 .MuiFormControlLabel-root .MuiButtonBase-root svg {
  font-size: 22px;
}
.Application_radio-btn__1w0E5 .MuiFormControlLabel-root .MuiFormControlLabel-label {
  font-family: 'Roboto', sans-serif;
  font-size: 15px;
  color: var(--secondary);
  padding-bottom: 4px;
}

.Application_chip-selected__2In5S,
.Application_chip-selected__2In5S:hover {
  background-color: var(--primary) !important;
  color: #fff;
}

.Application_chip-unselected__16w4_,
.Application_chip-unselected__16w4_:hover {
  background-color: #fff;
  border: 1px solid var(--primary);
  color: var(--primary);
}

.Application_label-text__15YdB {
  color: var(--tertiary);
}

/* Checkbox */
.Application_checkbox__1rsMw .MuiFormControlLabel-root .MuiButtonBase-root {
  padding: 0px 4px 4px 9px;
  align-self: flex-start;
  color: var(--tertiary);
}
.Application_checkbox__1rsMw .MuiFormControlLabel-root .MuiButtonBase-root.Mui-checked {
  color: var(--primary);
}
.Application_checkbox__1rsMw .MuiFormControlLabel-root .MuiButtonBase-root svg {
  font-size: 22px;
}
.Application_checkbox__1rsMw .MuiFormControlLabel-root .MuiFormControlLabel-label {
  font-family: 'Roboto', sans-serif;
  font-size: 15px;
  color: var(--tertiary);
  padding-bottom: 4px;
}

/*Add more and Remove*/

.Application_add-more-button__1NUj4 {
  background-color: transparent;
  border: none;
  box-shadow: none;
  font-family: 'Roboto', sans-serif;
  font-size: 13px;
  font-weight: 500;
  color: var(--primary);
  padding: 0px;
}

.Application_add-more-button__1NUj4:hover,
.Application_add-more-button__1NUj4:focus,
.Application_add-more-button__1NUj4:active {
  background-color: #fff;
  border: none;
  box-shadow: none !important;
}

.Application_add-more-button__1NUj4 .MuiTouchRipple-root {
  display: none;
}

.Application_remove-button__1eeZ2 {
  background-color: transparent;
  border: none;
  box-shadow: none;
  font-family: 'Roboto', sans-serif;
  font-size: 13px;
  font-weight: 500;
  color: #f40d13;
  padding: 0px;
}

.Application_remove-button__1eeZ2:hover,
.Application_remove-button__1eeZ2:focus,
.Application_remove-button__1eeZ2:active {
  background-color: #fff;
  border: none;
  box-shadow: none !important;
}

.Application_remove-button__1eeZ2 .MuiTouchRipple-root {
  display: none;
}

.Application_submit-butn-main__11SY0 {
  background-color: #fff;
  position: fixed;
  bottom: 0px;
  z-index: 2;
  border-top: 1px solid rgba(0, 0, 0, 0.12);
  padding: 15px 0px;
  text-align: center;
  width: -webkit-fill-available;
}

.Application_submit-butn-main__11SY0:after {
  content: '';
  display: block;
  height: auto;
  clear: both;
}

.Application_must-read__X4NK_ a {
  font-family: 'Roboto', sans-serif;
  text-decoration: none;
  color: var(--primary);
  cursor: pointer;
  font-weight: 500;
  font-size: 12px;
}

.Application_must-read__X4NK_ a:hover {
  text-decoration: none;
}

.Application_thank-you__3CzxJ {
  position: relative;
  padding-top: 40px;
}

.Application_thank-you__3CzxJ::before {
  content: "\4e";
  font-family: "dashboard-icons" !important;
  background-color: #fff;
  width: 42px;
  height: 42px;
  border: 3px solid #aee448;
  padding: 12px;
  border-radius: 90px;
  color: #aee448;
  display: block;
  line-height: 14px;
  text-align: center;
}

/*Applicant Payment*/

.Application_applicant-payment-bg__2NwiL {
  width: 720px;
  margin: 46px auto 0px;
  background-color: #fff;
  padding: 40px;
  border-radius: 6px;
  box-shadow: 2.5px 4.33px 9px 1px rgba(126, 149, 184, 0.04);
}

.Application_content-space__3x-rh {
  text-align: center;
}

.Application_coming-soon-text__ev7AA {
  margin-top: 10px;
}

.Application_payment-type-box-content__3v6hO {
  margin-bottom: 0px;
}

.Application_invoice-no__dGLsk {
  width: 100%;
  text-align: center;
  font-family: 'Roboto', sans-serif;
  font-size: 14px;
  font-weight: 400;
  color: #8e908f;
  padding: 0px 0px 0px;
}

.Application_applicant-name__20J9v {
  width: 100%;
  text-align: center;
  font-family: 'Roboto', sans-serif;
  font-size: 16px;
  font-weight: 500;
  color: #0d3f67 !important;
  letter-spacing: 0.5px;
  padding: 0px 0px 40px;
}

.Application_application-fee__1OTQy {
  width: 100%;
  text-align: center;
  font-family: 'Roboto', sans-serif;
  font-size: 14px;
  font-weight: 500;
  color: #000 !important;
  letter-spacing: 0.5px;
  border-bottom: 1px solid #e0e0e0;
  padding: 0px 0px 12px;
  margin-bottom: 12px;
}

.Application_application-amount__1ZjGx {
  width: 100%;
  text-align: left;
  font-family: 'Roboto', sans-serif;
  font-size: 13px;
  font-weight: 400;
  color: #333 !important;
  padding: 0px 0px 12px;
  border-bottom: 1px solid #e0e0e0;
  margin-bottom: 24px;
}

.Application_application-amount__1ZjGx span {
  font-size: 14px;
  font-weight: 700;
  float: right;
}

.Application_applicant-payemt-plz-wait__12DEk {
  width: 80%;
  z-index: 9;
  position: absolute;
  top: 100px;
  left: 50%;
  text-align: center;
  font-family: 'Roboto', sans-serif;
  font-size: 14px;
  font-weight: 400;
  color: #344750;
  -webkit-transform: translate(-50%, 0px);
          transform: translate(-50%, 0px);
}

.Application_applicant-payemt-plz-wait__12DEk .MuiAlert-standardInfo {
  text-align: left;
}

.Application_auto-fill-div__2rYS8 {
  position: absolute;
  right: 0px;
}

.Application_auto-fill__3kOA1 {
  color: var(--primary);
  width: auto;
  padding: 12px 24px 12px;
  text-align: center;
  font-weight: 600;
  cursor: pointer;
  border: 1px solid var(--primary);
  border-radius: 90px;
  margin-top: 15px;
  margin-right: 40px;
}

.Application_co-applicant-list__3-Rc3 {
  margin-top: 24px;
  font-size: 12px;
}

.Application_co-applicant-list__3-Rc3 h1 {
  margin: 0px 0px 15px;
}

.Application_co-applicant-list-view__3Sxbr {
  background-color: #f1f1f1;
  border-radius: 4px;
  padding: 0px 15px;
  color: #6e6e6e;
}

.Application_co-applicant-list-view__3Sxbr .MuiGrid-item {
  word-break: break-word;
}

.Application_co-applicant-list-view__3Sxbr .MuiGrid-item:last-child {
  text-align: right;
}

.Application_hidden__K8OOe {
  display: none;
}

.Application_form-grp__UjuVN .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] .MuiAutocomplete-input {
  padding: 0px !important;
}

/* Progress Info */
.Application_progress-info__5XVG1 {
  border-radius: 8px;
  background-color: #EDE2BC;
  margin-top: 16px;
  padding: 8px;
  color: #917243;
}

/* Payment form */
.Application_payment-title__2UbYv {
  background-color: #ebf3e4;
  padding: 0px 10px;
}

.Application_card-box__7FC5_ {
  display: inline-block;
}

.Application_card-box__7FC5_ h3 {
  font-size: 18px;
  font-weight: 600;
  color: #0d3f67;
  padding: 0;
}

.Application_card-box__7FC5_ span {
  margin: 0 10px;
}

.Application_autofill-button__2pURR {
  font-size: 13px;
  padding: 9px 21px;
  color: var(--primary);
}

.Application_autofill-button__2pURR .MuiTouchRipple-root {
  display: none;
}

.Application_state-box__wem8E .MuiInputBase-root {
  padding: 3px 0px 3px 8px !important;
}

.Application_radio-div__1xjDS {
  text-align: left;
  margin-bottom: 15px;
}

.Application_account-type-heading__1NTgv .MuiFormLabel-root {
  padding-top: 0px;
}

.Application_radio-div__1xjDS .MuiFormControl-root .MuiFormControlLabel-root .MuiTypography-root {
  border-radius: 90px;
  padding: 10px 20px;
}

.Application_radio-div__1xjDS label span.MuiTypography-root {
  background-color: #faf8ff;
  color: #434345;
  font-size: 14px;
  font-weight: 400;
  border: none;
}

.Application_radio-div__1xjDS .checked-amount-radio span.MuiTypography-root {
  /* color: #69647d;
  font-size: 14px;
  font-weight: 600;
  background-color: #d5cbff;
  border: none; */
}

.Application_dialog-amount__32RXO h6 {
  font-family: 'Roboto';
  font-style: normal;
  color: #10b983;
  font-size: 18px;
  font-weight: 500;
  padding: 5px 0;
}

h3.Application_dialog-title__RCQj8 {
  font-size: 17px;
  font-family: "Roboto";
  font-weight: 500;
  line-height: 1.6;
  letter-spacing: 0.0075em;
  margin: 0px;
}

.Application_dialog-label__2S9G- {
  font-family: 'Roboto';
  color: #6B738B;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  margin: 0px;
  letter-spacing: 0.5px;
}

.Application_dialog-data__3iTA2 h6 {
  font-family: 'Roboto';
  font-style: normal;
  color: #313131;
  font-size: 16px;
  font-weight: 500;
  padding: 5px 0;
}

.Application_dialog-footer__2l1kb {
  padding: 4px 16px;
}

.Application_cancel-button__2SEVc {
  background-color: #fff;
  border: 1px solid var(--primary);
  border-radius: 90px;
  font-size: 14px;
  color: var(--primary);
  font-weight: 500;
  padding: 8px 20px;
}

.Application_cancel-button__2SEVc:hover {
  background-color: transparent;
}

.Application_cancel-app-btn__31wDy {
  background-color: #cf142b;
  border: 1px solid #cf142b;
  border-radius: 90px;
  font-size: 14px;
  box-shadow: none;
  color: #fff !important;
  font-weight: 500;
  text-align: center;
  letter-spacing: 0.5px;
  padding: 8px 40px;
  text-shadow: -1px 1px 1px #33333357;
}

.Application_cancel-app-btn__31wDy:hover {
  background-color: #cf142b;
  box-shadow: none;
}

.Application_progress-info__5XVG1 svg {
  color: #caaa59;
  font-size: 16px;
}

.Application_progress-info__5XVG1 .Application_info__1JCSM {
  font-family: 'Roboto', sans-serif;
  font-size: 11px;
}

/* Progress Bar */
.Application_progress-bar__1RzF0 {
  margin-top: 20px;
}

.Application_progress-bar__1RzF0 .MuiLinearProgress-colorPrimary {
  background-color: #D7D7D7;
  border-radius: 5px;
}

.Application_progress-bar__1RzF0 .MuiLinearProgress-barColorPrimary {
  background-color: #11b666;
}

.Application_progress-bar__1RzF0 .Application_progress-count__2rBTg {
  color: #11b666;
  font-weight: 600;
  font-size: 12px;
}

.Application_progress-bar__1RzF0 .Application_progress-label__1dr68 {
  font-size: 12px;
  color: #606060;
  font-family: 'Roboto', sans-serif;
}

/* Upload Files Section */
.Application_upload-files-section__KyrWl {
  padding-top: 12px;
}

.Application_upload-files-button__1OBgc {
  font-family: 'Roboto', sans-serif;
  font-size: 13px;
  color: var(--primary);
  font-weight: 500;
  background-color: #fff;
  border: 1px solid var(--primary);
  border-radius: 20px;
  padding: 5px 16px;
}

.Application_upload-files-button__1OBgc:hover {
  background-color: #fff;
}

/* Uploaded Files Section */
.Application_uploaded-file__3rZ3Y .Application_file-view-icon__kY_zK svg {
  font-size: 18px;
}

.Application_uploaded-files-section___bCF- {
  margin-top: 15px;
}

.Application_uploaded-file__3rZ3Y {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
}

.Application_uploaded-file__3rZ3Y .Application_file-name__Pa_eS {
  font-size: 14px;
  color: #344750;
}

.Application_uploaded-file__3rZ3Y .Application_date-time__1v3A7 {
  font-size: 12px;
  color: #888;
}

.Application_uploaded-file__3rZ3Y .Application_file-view-icon__kY_zK {
  color: var(--primary);
  padding: 4px;
  top: -1px;
}

/* Tabs */
.Application_form-tabs-container__2sqL8 {
  margin-top: 16px;
}

.Application_form-tabs__3rrkm {
  background-color: #f9f9f9;
  margin-top: 8px;
  border-radius: 5px;
  min-height: 48px;
  padding-top: 0px;
  padding-bottom: 0px;
  color: #9499AA;
}

.Application_form-tabs__3rrkm:hover {
  color: #344750;
}

.Application_form-tabs__3rrkm .MuiTab-wrapper {
  flex-direction: row;
  justify-content: space-between;
  padding: 0px 8px;
}

.Application_form-tabs__3rrkm.Mui-selected {
  background-color: var(--primary);
  color: #fff;
}

.Application_form-tabs__3rrkm .Application_tab-label__1dfLF {
  font-family: 'Roboto', sans-serif;
}

.Application_form-tabs__3rrkm svg {
  color: #11b666;
}

.Application_fixed-wrap__wvTji {
  z-index: 99;
}

.Application_fixed-div__1J9tP {
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  background-color: #f2f3f7;
  padding: 0px 15px !important;
  z-index: 99;
}
.Application_year-picker__1lRYX label {
  -webkit-transform: translate(14px, -10px) scale(0.90) !important;
          transform: translate(14px, -10px) scale(0.90) !important;
  padding: 4px 20px 0px 4px;
  z-index: 1;
  background: #fff;
}
.Application_year-picker__1lRYX .MuiInput-underline {
  border: 1px solid #e2e8ef;
  border-radius: 4px;
  margin-top: 0px;
  padding: 11px 0px 11px 15px;
}
.Application_year-picker__1lRYX .MuiInput-underline.Mui-focused {
  border: 2px solid var(--primary);
  padding: 10px 0px 10px 15px;
}
.Application_year-picker__1lRYX .MuiInput-underline.Mui-error {
  border: 1px solid #f44336;
}
.Application_year-picker__1lRYX .MuiInput-underline:before, .Application_year-picker__1lRYX .MuiInput-underline:after {
  content: none;
}
.Application_year-picker__1lRYX svg {
  opacity: 0;
}
.Application_year-picker__1lRYX .MuiIconButton-label {
  position: relative;
}
.Application_year-picker__1lRYX .MuiIconButton-label::after {
  content: "\72";
  font-family: 'dashboard-icons';
  font-size: 16px;
  position: absolute;
  top: 4px;
  right: 4px;
  bottom: 0;
  vertical-align: middle;
  text-align: center;
  pointer-events: none;
}

@media screen and (max-width: 599px) {
  .Application_application-wrap__3xmqk h2 {
    position: relative;
  }

  .Application_application-contain-area__18x7P {
    padding: 15px 15px 40px;
  }

  .Application_remove-button__1eeZ2 {
    font-size: 12px;
  }

  .Application_applicant-payment-bg__2NwiL {
    width: 100%;
    padding: 25px 15px;
  }

  .Application_auto-fill__3kOA1 {
    margin-right: 15px;
    padding: 8px 20px;
    font-size: 12px;
  }

  .Application_login-logo__1jVQI img {
    max-width: 175px;
  }

  .Application_form-tabs__3rrkm {
    background-color: transparent;
    border-radius: 0px;
    padding: 0px;
    overflow: auto;
    min-height: 40px;
  }

  .Application_form-tabs__3rrkm.Mui-selected {
    border-bottom: 2px solid;
    background-color: transparent;
    color: var(--primary);
  }

  .Application_form-tabs__3rrkm .MuiTab-wrapper {
    padding: 0px;
    justify-content: center;
  }

  .Application_form-tabs__3rrkm .Application_tab-label__1dfLF {
    font-size: 11px;
    margin-top: 10px;
    line-height: 16px;
  }

  .Application_form-tabs__3rrkm svg {
    position: absolute;
    top: -4px;
    font-size: 18px;
  }

  .Application_form-tabs-container__2sqL8 {
    margin-top: 8px;
  }

  .Application_form-tabs-container__2sqL8 .MuiTabs-flexContainer {
    overflow: auto;
  }

  .Application_application-form-header__16sV9 {
    padding: 0px 15px;
  }

  .Application_form-grp__UjuVN {
    margin: 15px 15px 0px;
  }
}
.MustRead_must-read-con__3fquf {
  text-align: left;
  color: var(--secondary);
}

.MustRead_popup-top-area__x-qk_ .MuiDialogTitle-root h6 {
  float: left;
  color: var(--primary);
}

.MustRead_popup-top-area__x-qk_ .MuiDialogTitle-root button {
  float: right;
}

.MustRead_transunion_terms_of_service_checkbox__2tlHf {
  border-top: 1px solid #ebebf5;
  padding: 20px 0px 20px;
  margin-top: 20px;
}

.MustRead_transunion_terms_of_service_checkbox__2tlHf .MuiTypography-root {
  font-size: 14px;
  text-align: left;
  color: var(--tertiary);
}

.MustRead_transunion_terms_of_service_checkbox__2tlHf .MuiButtonBase-root {
  padding: 0px 15px;
  float: left;
  margin-top: -22px;
  color: var(--tertiary);
}

.MustRead_transunion_terms_of_service_checkbox__2tlHf .MuiCheckbox-colorPrimary.Mui-checked {
  color: var(--primary);
}

.MustRead_btn_accept__2m-CD, .MustRead_MuiButton-contained__2slFr.MustRead_Mui-disabled__38Lvz {
  width: auto !important;
  margin: 10px auto;
  padding: 8px 40px;
}
/*Dialog Box Size*/
.SignatureDialog_signature-popup__t2NnC .MuiDialog-paper {
  max-width: 800px !important;
  width: 800px;
}

/** dialog **/
.SignatureDialog_must-read-con__1uVM3 {
  text-align: left;
}

.SignatureDialog_popup-top-area__63oXb .MuiDialogTitle-root h6 {
  float: left;
  font-size: 26px;
  font-weight: 600;
}

.SignatureDialog_popup-top-area__63oXb .MuiDialogTitle-root button {
  float: right;
}

.SignatureDialog_transunion_terms_of_service_checkbox__222q8 {
  border-top: 1px solid #ebebf5;
  padding: 20px 0px 20px;
  margin-top: 20px;
}

.SignatureDialog_transunion_terms_of_service_checkbox__222q8 .MuiTypography-root {
  font-size: 14px;
  text-align: left;
}

.SignatureDialog_transunion_terms_of_service_checkbox__222q8 .MuiButtonBase-root {
  padding: 0px 15px;
  float: left;
  margin-top: -22px;
}

.SignatureDialog_btn_accept__15RUf,
.SignatureDialog_MuiButton-contained__vxGKm.SignatureDialog_Mui-disabled__2tH4m {
  width: auto !important;
  margin: 10px 0px;
  padding: 8px 40px;
}

/** dialog **/

/*section*/
section {
  width: 100%;
  padding: 0px;
  margin: 2% 0%;
}

.SignatureDialog_container__3V0HY {
  width: 100%;
  padding: 0px 0px 40px;
  margin: 0px;
}

.SignatureDialog_container__3V0HY:after {
  content: '';
  display: block;
  clear: both;
  height: auto;
}

.SignatureDialog_container__3V0HY h1 {
  padding: 15px;
  font-size: 18px;
  color: #fff;
  text-align: left;
  font-weight: 400;
  margin: 0px 0px 0px;
}

.SignatureDialog_content__1s2RJ {
  /* width: 900px; */
  padding: 0px;
  margin: 0px auto;
}

.SignatureDialog_content__1s2RJ h1 {
  width: 100%;
  font-size: 26px;
  font-weight: 700;
  text-align: left;
  color: #000;
  padding: 0px 0px 0px;
  margin: 0px 0px 0px;
}

.SignatureDialog_content__1s2RJ h2 {
  font-size: 16px;
  font-weight: bold;
  text-align: left;
  color: #333;
  padding: 0px 0px 15px;
  margin: 0px 0px 15px;
}

.SignatureDialog_content__1s2RJ h2:last-child {
  padding: 0px 0px 0px;
  margin: 0px 0px 0px;
  border-bottom: 0px solid #ccc;
}

.SignatureDialog_content__1s2RJ h3 {
  font-size: 16px;
  font-weight: bold;
  text-align: center;
  color: #333;
  padding: 0px 0px 15px;
  margin: 0px 0px 15px;
}

.SignatureDialog_content__1s2RJ p {
  font-size: 16px;
  font-weight: normal;
  text-align: left;
  color: #6c6c6c;
  padding: 0px 0px 0px;
  margin: 0px 0px 0px;
  line-height: 18px
}

.SignatureDialog_tab-menu__3ZTXg {
  width: 100%;
  padding: 0px 0px;
  margin: 30px 0px 0px;
  border-bottom: 1px solid #e2e2e2;
}

.SignatureDialog_tab-menu__3ZTXg li {
  width: auto;
  padding: 0px 0px;
  margin: 0px;
  display: inline-block;
  list-style: none;
}

.SignatureDialog_tab-menu__3ZTXg li a {
  width: auto;
  padding: 16px 72px;
  margin: 0px;
  display: block;
  font-weight: 500;
  font-size: 20px;
  text-align: center;
  color: #3d3d3d;
  text-decoration: none;
  letter-spacing: 1px;
  border-bottom: 3px solid #fff;
}

.SignatureDialog_tab-menu__3ZTXg li a:hover,
.SignatureDialog_tab-menu__3ZTXg li a:focus,
.SignatureDialog_tab-menu__3ZTXg li a:active,
.SignatureDialog_tab-menu__3ZTXg li a.SignatureDialog_active__2NgTT {
  color: var(--primary);
  border-bottom: 3px solid var(--primary);
}

.SignatureDialog_tab-content__2azbC>div {
  display: none;
}

.SignatureDialog_tab-content__2azbC .SignatureDialog_active__2NgTT {
  display: block;
}

.SignatureDialog_tab-type__1bUuM p {
  font-weight: 400;
  font-size: 16px;
  color: #7f7f7f;
  text-align: left;
  padding: 24px 0px 15px 5px;
  margin: 0px 0px;
  letter-spacing: 0.5px;
}

.SignatureDialog_tab-type__1bUuM button {
  font-weight: normal;
  font-size: 28px;
  color: #404040;
  border-radius: 0px;
  background-color: #fff;
  border: 1px solid #e2e2e2;
  padding: 0px 60px;
  margin: 5px 5px;
  cursor: pointer;
  width: 48%;
  min-height: 100px;
  float: left;
}


/* refer fontFamilies variable if modifying */
.SignatureDialog_font0__24LAw {
  font-family: 'Hurricane', cursive;
}

.SignatureDialog_font1__HZP3f {
  font-family: 'Satisfy', cursive;
}

.SignatureDialog_font2__2EHJR {
  font-family: 'Caveat', cursive;
}

.SignatureDialog_font3__1f-9y {
  font-family: 'Homemade Apple', cursive;
}

.SignatureDialog_font4__21yOO {
  font-family: 'Vujahday Script', cursive;
}

.SignatureDialog_font5__2zgNF {
  font-family: 'Cedarville Cursive', cursive;
}

.SignatureDialog_tab-type__1bUuM button:hover,
.SignatureDialog_tab-type__1bUuM button:focus,
.SignatureDialog_tab-type__1bUuM button:active,
.SignatureDialog_tab-type__1bUuM button.SignatureDialog_active__2NgTT {
  color: var(--primary);
  border-radius: 0px;
  background-color: #f1efff;
  border: 1px solid var(--primary);
}

.SignatureDialog_add-signature__DGGW- {
  width: 100%;
  padding: 0px;
  margin: 42px 0px 0px;
  text-align: right;
}

.SignatureDialog_add-signature__DGGW- button.SignatureDialog_add__ASlvJ {
  font-family: 'Roboto', sans-serif;
  font-weight: 500;
  font-size: 18px;
  color: #fff;
  border-radius: 4px;
  background-color: #236cae;
  border: 1px solid #236cae;
  padding: 16px 74px;
  margin: 5px 5px;
  cursor: pointer;
  letter-spacing: 1px;
}

.SignatureDialog_add-signature__DGGW- button.SignatureDialog_add__ASlvJ:hover,
.SignatureDialog_add-signature__DGGW- button.SignatureDialog_add__ASlvJ:focus,
.SignatureDialog_add-signature__DGGW- button.SignatureDialog_add__ASlvJ:active,
.SignatureDialog_add-signature__DGGW- button.SignatureDialog_add__ASlvJ.SignatureDialog_active__2NgTT {
  color: #fff;
  border-radius: 4px;
  background-color: #236cae;
  border: 1px solid #236cae;
}

.SignatureDialog_cancel__1_J_b {
  font-family: 'Roboto', sans-serif;
  font-weight: 500;
  font-size: 16px;
  color: #74787b;
  border-radius: 4px;
  background-color: #fff;
  border: 1px solid #fff;
  padding: 16px 42px;
  margin: 5px 5px;
  cursor: pointer;
  letter-spacing: 1px;
  box-shadow: none;
}

.SignatureDialog_cancel__1_J_b:hover,
.SignatureDialog_cancel__1_J_b:focus,
.SignatureDialog_cancel__1_J_b:active,
.SignatureDialog_cancel__1_J_b.SignatureDialog_active__2NgTT {
  color: #74787b;
  border-radius: 4px;
  background-color: #fff;
  border: 1px solid #fff;
  box-shadow: none;
}

.SignatureDialog_cancel__1_J_b .MuiTouchRipple-root {
  display: none;
}

.SignatureDialog_tab-draw__3sXLz .SignatureDialog_signature-box__hySVD {
  position: relative;
  background-color: #fff;
  border-radius: 4px;
  border: 1px solid #e0e0e0;
  /* padding: 24px; */
  margin-top: 0px;
  height: auto;
}


.SignatureDialog_tab-draw__3sXLz .SignatureDialog_signature-box__hySVD button {
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
  font-size: 16px;
  color: #236cae;
  border-radius: 4px;
  background-color: #fff;
  border: 1px solid #aacee0;
  padding: 12px 20px;
  margin: 0px;
  cursor: pointer;
  letter-spacing: 1px;
  float: right;
}

.SignatureDialog_tab-draw__3sXLz .SignatureDialog_signature-box__hySVD button:hover,
.SignatureDialog_tab-draw__3sXLz .SignatureDialog_signature-box__hySVD button:focus,
.SignatureDialog_tab-draw__3sXLz .SignatureDialog_signature-box__hySVD button:active,
.SignatureDialog_tab-draw__3sXLz .SignatureDialog_signature-box__hySVD button.SignatureDialog_active__2NgTT {
  color: #236cae;
  border-radius: 4px;
  background-color: #fff;
  border: 1px solid #aacee0;
}

.SignatureDialog_tab-draw__3sXLz .SignatureDialog_signature-box__hySVD .SignatureDialog_signature__2yOeY canvas {
  font-weight: 400;
  font-size: 25px;
  color: #626262;
  width: 100%;
  text-align: center;
}

.SignatureDialog_tab-draw__3sXLz p {
  font-weight: 400;
  font-size: 16px;
  color: #7f7f7f;
  text-align: left;
  padding: 24px 0px 15px 5px;
  margin: 0px 0px;
  letter-spacing: 0.5px;
}

.SignatureDialog_canvas-cls__2vIkY {
  /* for test */
  border: 1px solid red;
}

.SignatureDialog_canvas-clear__3DBpW {
  position: absolute;
  right: 5px;
  top: 5px;
}

.SignatureDialog_signature-popup__t2NnC .MuiDialogActions-root {
  padding: 0px 42px 15px;
}

@media only screen and (max-width: 600px) and (min-width: 320px) {
  .SignatureDialog_popup-top-area__63oXb .SignatureDialog_MuiDialogTitle-root__JHfRt h6 {
    font-size: 18px;
  }

  .SignatureDialog_tab-menu__3ZTXg li {
    width: 100%;
  }

  .SignatureDialog_tab-menu__3ZTXg li a {
    padding: 8px;
    font-size: 16px;
  }

  .SignatureDialog_tab-type__1bUuM button {
    font-weight: normal;
    font-size: 18px;
    color: #404040;
    border-radius: 0px;
    background-color: #fff;
    border: 1px solid #e2e2e2;
    padding: 0px 15px;
    margin: 5px 5px;
    cursor: pointer;
    width: 100%;
    min-height: 50px;
    float: left;
  }
}
.ApplicantSign_body__1nGk- {
  font-family: Arial, Helvetica, sans-serif;
  font-weight: normal;
  text-align: left;
  color: #000;
  background-color: #fff;
  padding: 0px;
  margin: 40px 40px;
}



.ApplicantSign_container__1xMmj {
  width: 100%;
  padding: 0px 0px 40px;
  margin: 0px;
}

.ApplicantSign_container__1xMmj:after {
  content: '';
  display: block;
  clear: both;
  height: auto;
}

.ApplicantSign_container__1xMmj h1 {
  background-color: #555555;
  padding: 15px;
  font-size: 18px;
  color: #fff;
  text-align: left;
  font-weight: 400;
  margin: 0px 0px 40px;
}

.ApplicantSign_content__3Y5rK {
  width: 96%;
  padding: 0px;
  margin: 0px auto;
}

.ApplicantSign_content__3Y5rK h1 {
  width: 100%;
  font-size: 24px;
  font-weight: bold;
  text-align: center;
  color: #000;
  padding: 0px 0px 15px;
  margin: 0px 0px 15px;
  background-color: transparent;
}

.ApplicantSign_content__3Y5rK h2 {
  font-size: 16px;
  font-weight: bold;
  text-align: left;
  color: #333;
  padding: 0px 0px 15px;
  margin: 0px 0px 15px;
  border-bottom: 1px solid #ccc;
}

.ApplicantSign_content__3Y5rK h2:last-child {
  padding: 0px 0px 0px;
  margin: 0px 0px 0px;
  border-bottom: 0px solid #ccc;
}

.ApplicantSign_content__3Y5rK h3 {
  font-size: 16px;
  font-weight: bold;
  text-align: center;
  color: #333;
  padding: 0px 0px 15px;
  margin: 0px 0px 15px;
}

.ApplicantSign_content__3Y5rK p {
  font-size: 14px;
  font-weight: normal;
  text-align: left;
  color: #333;
  padding: 0px 0px 15px;
  margin: 0px 0px 0px;
  line-height: 24px
}

.ApplicantSign_content__3Y5rK p a {
  color: #333;
}

.ApplicantSign_content__3Y5rK ul {
  width: auto;
  padding: 0px 0px 0px 40px;
  margin: 0px;
}

.ApplicantSign_content__3Y5rK ul li {
  font-size: 14px;
  font-weight: normal;
  text-align: left;
  color: #333;
  padding: 0px 0px 15px;
  margin: 0px 0px 0px;
  line-height: 24px
}

.ApplicantSign_content__3Y5rK ul li a {
  color: #333;
}

.ApplicantSign_content__3Y5rK ul li ul {
  padding: 20px 0px 20px 40px;
}

.ApplicantSign_content__3Y5rK ul li ul li {
  list-style: disc;
}

.ApplicantSign_content__3Y5rK p.ApplicantSign_align-center__htKq7 {
  text-align: center;
}

.ApplicantSign_content__3Y5rK .ApplicantSign_padding-top__1j70V {
  padding-top: 60px;
}

.ApplicantSign_content__3Y5rK table {
  width: 100%;
  border-collapse: collapse;
}

.ApplicantSign_content__3Y5rK table tbody tr td {
  width: 50%;
  border: 1px solid #000;
  padding: 15px;
  font-size: 14px;
  font-weight: normal;
  color: #333;
  line-height: 18px;
  vertical-align: top;
}

.ApplicantSign_content__3Y5rK table tbody tr td ol {
  padding: 0px 0px 0px 30px;
  margin: 0px;
  display: inline-grid;
}

.ApplicantSign_content__3Y5rK table tbody tr td ol li {
  padding-bottom: 15px;
}


.ApplicantSign_checkbox__2w13S {
  width: 100%;
  padding-top: 15px;
  float: left;
}

.ApplicantSign_checkbox__2w13S input {
  width: 24px;
  height: 24px;
  float: left;
}

.ApplicantSign_checkbox__2w13S p {
  line-height: 32px;
  padding-left: 42px;
  padding-bottom: 0px
}

.ApplicantSign_checkbox__2w13S p.ApplicantSign_gray-text__2wPkz {
  font-size: 12px;
  color: #999;
}

/* SIGNATURE_CSS_COPIED_FROM_L2L_INDEX_CSS */
/* signature */
.ApplicantSign_signature-main__3psA1 table {
  width: 100%;
}

.ApplicantSign_signature-main__3psA1 table tbody tr {
  justify-content: space-between;
}

.ApplicantSign_signature-main__3psA1 table tbody tr td {
  width: 46%;
  margin: 0% 1%;
  display: inline-block;
}

.ApplicantSign_signature-main__3psA1 table tbody tr td p {
  font-weight: 600;
  font-size: 14px;
  color: #333;
}

.ApplicantSign_signature-main__3psA1 table tbody tr td p span {
  font-weight: 400;
  font-size: 13px;
  color: #666;
  padding-left: 6px;
}

.ApplicantSign_signature-area__2y9FS {
  border: 1px solid #ccc;
  padding: 15px;
  margin: 15px 0px;
  /* min-height: 156px; */
}

.ApplicantSign_signature-area__2y9FS:after {
  clear: both;
  content: '';
  display: block;
  height: auto;
}

.ApplicantSign_signature-box__1T2X_ {
  width: auto;
  float: right;
  padding: 15px 30px 15px 0px;
  margin: 0px;
  font-size: 14px;
  text-align: left;
  color: #112268;
}

.ApplicantSign_signature-after-exam__2aEj7 {
  width: 100%;
  float: left;
  padding: 0px;
  margin: 0px;
  font-size: 14px;
  text-align: left;
  color: #112268;
}

.ApplicantSign_signature-container__3W38- {
  padding: 0px 8px;
}

.ApplicantSign_signature-container__3W38- h1 {
  padding: 0px;
  margin: 0px;
}

.ApplicantSign_signature-container__3W38- h2 {
  padding: 0;
  margin-top: 30px;
}

.ApplicantSign_signature-line__3m-ov {
  width: 96%;
  margin: 0px auto 15px;
  border-bottom: 1px solid #ccc;
  padding-top: 60px;
  padding-bottom: 0px;
  color: #ccc;
}

.ApplicantSign_signature-font__3J5LD {
  background-color: initial !important;
  color: blue !important;
  font-size: 30px !important;
  position: relative !important;
  text-align: left !important;
}

.ApplicantSign_submit-btn-container__vYZIU {
  text-align: center;
}

.ApplicantSign_signature-note__3cRzB {
  font-size: 11px;
  padding-top: 15px;
  color: var(--tertiary)
}

.ApplicantSign_after-exam-con__2YaLw {
  margin-left: 42px;
  margin-bottom: 0px;
}

.ApplicantSign_signature-after-exam__2aEj7.ApplicantSign_disable-esign__2yhCE {
  cursor: not-allowed;
}

.ApplicantSign_disable-esign__2yhCE .ApplicantSign_signature-line__3m-ov {
  pointer-events: none;
}

/* SIGNATURE_CSS_COPIED_FROM_L2L_INDEX_CSS */


@media screen and (max-width: 600px) and (min-width: 320px) {
  .ApplicantSign_signature-main__3psA1 table tbody tr td {
    width: 98%;
    margin: 0% 1%;
    display: inline-block;
  }
}
.ApplicantExam_login-logo__3SdyX {
  width: 168px;
  margin: 0px auto 30px; 
}

.ApplicantExam_login-logo__3SdyX img {
  object-fit: contain;
  object-position: center;
  width: 100%;
  height: 70px;
}

.ApplicantExam_applicant-exam-main__3L33p {
    width: 80%;
    margin: 0 auto;
    background-color: #fff;
    border-radius: 6px;
    /* margin-top: 80px; */
    padding: 0px 35px 40px;
}

.ApplicantExam_logo__38UEj {
    width: 100%;
    text-align: center;
    margin: 0px auto 30px;
}

.ApplicantExam_applicant-exam-main__3L33p h3 {
    font-family: 'Roboto', sans-serif;
    font-size: 24px;
    font-weight: 400;
    color: #000;
    text-align: center;
    padding: 0px 0px 40px;
    margin: 0px;
}

.ApplicantExam_applicant-exam-main__3L33p h4 {
    font-family: 'Roboto', sans-serif;
    font-size: 14px;
    font-weight: 400;
    color: #8e908f;
    text-align: center;
    padding: 0px 0px 10px;
    margin: 0px;
}

.ApplicantExam_question-answer-main__2ZxfB {
    width: 100%;
    margin: 0px;
    padding: 0px 0px 40px;
}

.ApplicantExam_question-answer-main__2ZxfB label.MuiFormLabel-root {
    font-family: 'Roboto', sans-serif;
    font-size: 14px;
    font-weight: 500;
    color: #344750;
    text-align: left;
    padding: 0px 0px 10px;
    margin: 0px;
    line-height: 24px;
}

.ApplicantExam_question-answer-main__2ZxfB .MuiTypography-root {
    font-family: 'Roboto', sans-serif;
    font-size: 14px;
    font-weight: 400;
    color: #8e908f;
    text-align: left;
    padding: 0px 0px 0px;
    margin: 0px;
}

.ApplicantExam_applicant_info__3pEvE {
    border: 1px solid #e7ecf2;
    border-radius: 6px;
    background-color: #fff;
    /* box-shadow: 2.5px 4.33px 9px 1px rgba(126, 149, 184, 0.04); */
    padding: 30px !important;
}

.ApplicantExam_detail_main__1lk-u {
    margin-right: 24px;
    padding-right: 24px;
    border-right: 1px solid #e7ecf2;
    float: left;
}

.ApplicantExam_detail_label__1nMzc {
    color: #6e8496;
    font-size: 14px;
    padding-bottom: 8px;
}

.ApplicantExam_detail_info__1_Jui {
    color: #0d3f67;
    font-size: 14px;
    font-weight: 600;
    text-transform: none !important;
    cursor: pointer;
}

.ApplicantExam_exam-button__2A2B6 .MuiButton-root.Mui-disabled {
    opacity: 0.5;
}

@media only screen and (max-width: 600px) and (min-width: 320px) {
    .ApplicantExam_applicant-exam-main__3L33p {
        width: 100%;
        padding: 0px 0px 30px;
    }
}
/*Application Form*/
.ApplicantLogin_applicant-login-wrap__3T4br {
  width: 80%;
  margin: 0 auto;
  position: relative;
}

.ApplicantLogin_applicant-login-wrap__3T4br h2 {
  font-family: 'Roboto', sans-serif;
  font-size: 22px;
  font-weight: 400;
  color: #000;
  text-align: center;
  padding-top: 15px;
  letter-spacing: 1px;
}

.ApplicantLogin_applicant-login-container__LaWA- {
  width: 100%;
  background-color: #fff;
  border-radius: 6px;
  margin-top: 98px;
}

.ApplicantLogin_applicant-login-container__LaWA- h1 {
  font-family: 'Roboto', sans-serif;
  font-size: 24px;
  font-weight: 600;
  color: #344750;
  text-align: center;
  padding: 20px 0px 24px;
  margin: 0px;
  width: 100%;
}

.ApplicantLogin_applicant-login-container__LaWA- h4 {
  font-family: 'Roboto', sans-serif;
  font-size: 14px;
  font-weight: 400;
  color: #344750;
  text-align: center;
  padding: 0px 0px 40px;
  margin: 0px;
  width: 100%;
}

.ApplicantLogin_applicant-login-container__LaWA- h5 {
  font-family: 'Roboto', sans-serif;
  font-size: 14px;
  font-weight: 400;
  color: #344750;
  text-align: center;
  padding: 0px 0px 0px;
  margin: 0px;
  width: 100%;
  line-height: 24px;
}

.ApplicantLogin_applicant-login-container__LaWA-:after {
  content: '';
  clear: both;
  display: block;
  height: 100%;
}

.ApplicantLogin_thank-you__2JiUI {
  position: relative;
  padding-top: 40px;
}

.ApplicantLogin_thank-you__2JiUI::before {
  content: "\4e";
  font-family: "dashboard-icons" !important;
  background-color: #fff;
  width: 42px;
  height: 42px;
  border: 3px solid #aee448;
  padding: 12px;
  border-radius: 90px;
  color: #aee448;
  display: block;
  line-height: 14px;
  text-align: center;
}

.ApplicantLogin_forgot-password__1lQoY {
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 14px;
  font-weight: 400;
  color: #6e8496;
  text-align: center;
  text-decoration: none;
  line-height: 14px;
}

.ApplicantLogin_forgot-password__1lQoY:hover {
  text-decoration: none;
}

.ApplicantLogin_tenant-login__Q2HFu, .ApplicantLogin_tenant-login__Q2HFu:hover{
  padding: 8px 20px;
  background-color: #fff;
  border: 1px solid #cdd0d5d4;
  border-radius: 90px;
  font-size: 13px;
  color: #5e6a78;
  font-weight: 400;
  text-align: center;
  letter-spacing: 0.5px;
  box-shadow: none;
  line-height: 22px;
  position: relative;
  text-decoration: none;
  margin-top: 30px;
  display: inline-block;
}

@media only screen and (max-width: 1024px) {
  .ApplicantLogin_applicant-login-wrap__3T4br {
    width: 100%;
  }
}

@media screen and (max-width: 600px) and (min-width: 320px) {
  .ApplicantLogin_applicant-login-wrap__3T4br h2 {
    position: relative;
  }

  .ApplicantLogin_applicant-login-container__LaWA- {
    width: 95%;
    margin: 10px auto 0px;
  }

  .ApplicantLogin_mb-forgot-password__nzeBR {
    line-height: 65px;
    text-align: center;
    width: 100%;
  }
}
.PreApplicationForm_invalid-unit__wttSk {
  width: 50%;
  margin: 50px auto 0px;
  text-align: center;
  padding: 16px;
  background-color: #ffffff;
  border-radius: 6px;
  color: #606060;
  letter-spacing: 0.5px;
  font-weight: 400;
  border: 1px solid #f7f6f6;
}

.PreApplicationForm_guestcard-bg__3MuIY {
  background-color: #E5E5E5;
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0px;
  left: 0px;
  z-index: -1;
}

.PreApplicationForm_guestcard-wrap__1qwXZ {
  width: 80%;
  margin: 65px auto;
  background-color: #fff;
  z-index: 1;
  padding-bottom: 42px;
}

.PreApplicationForm_preappform-contact-form__2X9qN {
  padding: 24px 42px 0px 42px;
}

.PreApplicationForm_preappform-contact-form__2X9qN h2 {
  padding: 0px;
}


/*Tab Menu*/
.PreApplicationForm_tab-wrap__1HY2v {
  width: calc(100% - 84px);
  height: 65px;
  padding: 15px 0px 0px;
  margin: 0px auto;
  background-color: #fff;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.PreApplicationForm_tab-wrap__1HY2v .MuiTabs-root {
  display: inline-block;
  height: auto;
  min-height: inherit;
}

.PreApplicationForm_tabs-indicator__1vWan a {
  font-size: 18px;
  color: #8E93AF;
  font-weight: 500;
  border-bottom: 0px;
  border-left: 0px;
  border-top: 0px;
  text-decoration: none;
  padding: 12px 18px 12px 18px;
  max-width: inherit;
  letter-spacing: 0.5px;
  position: relative;
  overflow: inherit;
  opacity: 1;
}

.PreApplicationForm_tabs-indicator__1vWan a:hover {
  text-decoration: none;
}

.PreApplicationForm_tabs-indicator__1vWan a:first-child {
  margin-right: 60px;
  padding: 12px 18px 12px 40px;
}

.PreApplicationForm_tabs-indicator__1vWan a:first-child::before {
  content: '1';
  display: block;
  width: 30px;
  height: 30px;
  background-color: #8E93AF;
  position: absolute;
  left: 0px;
  top: 12x;
  border-radius: 90px;
  color: #fff;
  opacity: 0.7;
}

.PreApplicationForm_tabs-indicator__1vWan a:first-child::after {
  content: '';
  display: block;
  width: 60px;
  height: 1px;
  background-color: #c9c9c9;
  position: absolute;
  right: -60px;
  top: 28px;
}

.PreApplicationForm_tabs-indicator__1vWan a:nth-child(2) {
  margin-right: 80px;
  padding: 12px 18px 12px 60px;
}

.PreApplicationForm_tabs-indicator__1vWan a:nth-child(2)::before {
  content: '2';
  display: block;
  width: 30px;
  height: 30px;
  background-color: #8E93AF;
  position: absolute;
  left: 20px;
  top: 12px;
  border-radius: 90px;
  color: #fff;
  opacity: 0.7;
}

.PreApplicationForm_tabs-indicator__1vWan a .MuiTouchRipple-root {
  display: none;
}

.PreApplicationForm_tabs-indicator__1vWan a.Mui-selected {
  color: #313131;
}

.PreApplicationForm_tabs-indicator__1vWan a.Mui-selected:first-child::before,
.PreApplicationForm_tabs-indicator__1vWan a.Mui-selected:nth-child(2)::before {
  background-color: #313131;
}

.PreApplicationForm_tabs-indicator__1vWan .MuiTabs-indicator {
  display: none;
}

.PreApplicationForm_autofill-butn__326bb {
  background-color: transparent;
  border: 1px solid var(--primary);
  color: var(--primary) !important;
  font-size: 14px;
  float: right;
  margin: 0px 0px 0px 0px;
  padding: 0px 34px;
  line-height: 42px;
  text-shadow: none;
}

.PreApplicationForm_autofill-butn__326bb:hover,
.PreApplicationForm_autofill-butn__326bb:focus {
  background-color: transparent;
  border: 1px solid var(--primary);
  box-shadow: none;
  color: var(--primary);
}

.PreApplicationForm_autofill-butn__326bb .MuiTouchRipple-root {
  display: none;
}

.PreApplicationForm_tab-wrap__1HY2v .PreApplicationForm_back-butn__TscFA {
  background-color: transparent;
  border: 1px solid #E3E5F1 !important;
  color: #606060;
  font-size: 14px;
  float: right;
  margin: 0px 30px 0px 30px;
  padding: 8px 40px;
}

.PreApplicationForm_tab-wrap__1HY2v .PreApplicationForm_back-butn__TscFA .MuiTouchRipple-root {
  display: none;
}

.PreApplicationForm_submit-butn__2zVYq,
.PreApplicationForm_submit-butn__2zVYq:hover,
.PreApplicationForm_submit-butn__2zVYq:focus {
  background-color: #313131;
  border-color: #313131;
  font-size: 16px;
  font-weight: 500;
  padding: 10px 34px;
}

.PreApplicationForm_property-title__CcUGM {
  position: relative;
  font-size: 22px !important;
  padding: 0px !important;
}

.PreApplicationForm_unit-label__WlilH {
  font-size: 14px;
  color: var(--tertiary);
}

.PreApplicationForm_divide__1X6G1 {
  color: var(--tertiary);
}

.PreApplicationForm_property-container__3PRSl {
  padding-top: 25px;
}

.PreApplicationForm_property-title__CcUGM::before {
  content: "\38";
  font-family: 'dashboard-icons';
  font-size: 17px;
  color: var(--tertiary);
  position: absolute;
  top: 3px;
  left: -26px;
  bottom: 0;
  vertical-align: middle;
  text-align: center;
  pointer-events: none;
}


@media only screen and (max-width: 912px) {
  .PreApplicationForm_tab-wrap__1HY2v {
    display: block;
  }

  .PreApplicationForm_autofill-butn__326bb {
    float: left;
    margin-bottom: 20px;
  }

  .PreApplicationForm_form-title__3N--S {
    font-size: 22px !important;
  }

}

@media only screen and (max-width: 414px) and (min-width: 320px) {
  .PreApplicationForm_guestcard-wrap__1qwXZ {
    width: 95%;
    margin: 10px auto;
  }

  .PreApplicationForm_submit-butn__2zVYq,
  .PreApplicationForm_submit-butn__2zVYq:hover,
  .PreApplicationForm_submit-butn__2zVYq:focus {
    font-size: 14px;
    padding: 8px 32px;
  }

  .PreApplicationForm_tab-wrap__1HY2v {
    display: block;
    width: calc(100% - 40px);
  }

  .PreApplicationForm_tabs-indicator__1vWan a:first-child {
    width: 100%;
  }

  .PreApplicationForm_tabs-indicator__1vWan a:first-child::after {
    display: none;
  }

  .PreApplicationForm_tabs-indicator__1vWan a:first-child span {
    text-align: left;
    font-size: 16px;
    align-items: flex-start;
  }

  .PreApplicationForm_tabs-indicator__1vWan a:nth-child(2) {
    display: block;
    width: 100%;
    padding-left: 40px;
  }

  .PreApplicationForm_tabs-indicator__1vWan a:nth-child(2) span {
    text-align: left;
    font-size: 16px;
    align-items: flex-start;
  }

  .PreApplicationForm_tabs-indicator__1vWan a:nth-child(2)::before {
    left: 0px;
  }

  .PreApplicationForm_tabs-indicator__1vWan .MuiTabs-flexContainer {
    display: block;
  }

  .PreApplicationForm_tab-wrap__1HY2v .MuiTabs-root {
    display: block;
  }

  .PreApplicationForm_autofill-butn__326bb {
    float: left;
    padding: 0px 30px;
    line-height: 38px;
    margin-bottom: 20px;
  }

  .PreApplicationForm_guestcard-wrap__1qwXZ .MuiFormControl-root {
    margin-bottom: 10px;
  }

  .PreApplicationForm_form-title__3N--S {
    font-size: 20px !important;
  }

  .PreApplicationForm_preappform-contact-form__2X9qN {
    padding-left: 21px;
    padding-right: 21px;
  }

  .PreApplicationForm_property-container__3PRSl {
    padding-left: 20px;
  }

}
body {
  height: auto;
}

.GuestcardContactForm_guestcard-contact-form__a_-S4 {
  width: calc(100% - 84px);
  padding: 42px 42px 0px;
}

.GuestcardContactForm_form-wrap__iZwQV .GuestcardContactForm_guestcard-wrap__1tetH .MuiInputBase-root textarea {
  height: 20px !important;
}

.GuestcardContactForm_guestcard-contact-form__a_-S4 input[type="date"]::-webkit-inner-spin-button,
.GuestcardContactForm_guestcard-contact-form__a_-S4 input[type="date"]::-webkit-calendar-picker-indicator {
  font-size: 18px;
  color: var(--tertiary);
  margin-right: 8px;
  -webkit-appearance: none;
  opacity: 0.5;
  cursor: pointer;
}

.GuestcardContactForm_fetching__2kDJA {
  color: #0566f8;
  font-size: 14px;
  font-weight: 500;
}

.GuestcardContactForm_submit-butn__3ab9x,
.GuestcardContactForm_submit-butn__3ab9x:hover,
.GuestcardContactForm_submit-butn__3ab9x:focus {
  background-color: #313131;
  border-color: #313131;
  font-size: 16px;
  font-weight: 500;
  padding: 10px 34px;
}

.GuestcardContactForm_comment-box__2A30n {
  margin-bottom: 0px;
}

.GuestcardContactForm_autofill-butn__3lTI_ {
  background-color: transparent;
  border: 1px solid #c5c5c5;
  color: #313131;
  font-size: 14px;
  float: right;
  margin: 0px 0px 0px 0px;
  padding: 0px 34px;
  line-height: 42px;
}

.GuestcardContactForm_autofill-butn__3lTI_:hover,
.GuestcardContactForm_autofill-butn__3lTI_:focus {
  background-color: transparent;
  border: 1px solid #c5c5c5;
  box-shadow: none;
  color: #313131;
}

.GuestcardContactForm_autofill-butn__3lTI_ .MuiTouchRipple-root {
  display: none;
}

.GuestcardContactForm_guestcard-contact-form__a_-S4 .radio-butn .checked-amount-radio span.MuiTypography-root,
.GuestcardContactForm_guestcard-contact-form__a_-S4 .radio-butn .checked-amount-radio label span.MuiTypography-root {
  background-color: #313131;
  border: 1px solid #313131;
}

.GuestcardContactForm_state-box__1mLjX .MuiInputBase-root {
  padding: 3px 0px 3px 8px !important;
}

.GuestcardContactForm_guestcard-contact-form__a_-S4 input.masked-phone-number {
  width: 100%;
  display: inline-block;
  padding: 11px 0px 11px 15px;
  box-sizing: border-box;
}

.GuestcardContactForm_guestcard-contact-form__a_-S4 .MuiFormHelperText-root.Mui-error {
  word-break: break-all;
}

.GuestcardContactForm_card-box__3-Cve {
  display: inline-block;
}

.GuestcardContactForm_card-box__3-Cve p {
  font-size: 16px;
  font-weight: 400;
  color: var(--tertiary);
}

.GuestcardContactForm_card-box__3-Cve h3 {
  font-size: 18px;
  font-weight: 600;
  color: var(--primary);
  padding: 0;
}

.GuestcardContactForm_card-box__3-Cve span {
  margin: 0 10px;
}

.GuestcardContactForm_equal-sign__2ciG2 {
  color: #9a9a9a;
  font-size: 18px;
}

.GuestcardContactForm_total-div__2qkda {
  display: flex;
  align-items: flex-end;
  margin-bottom: 23px;
}

.GuestcardContactForm_card-box__3-Cve .GuestcardContactForm_total-amt__15a8z {
  font-size: 16px;
  font-weight: 400;
  color: var(--tertiary);
}

.GuestcardContactForm_card-div__GqR5r {
  margin-left: 10px;
}

.GuestcardContactForm_autofill-button__TEtF7 {
  color: #313131;
  background-color: #fff;
}

.GuestcardContactForm_autofill-button__TEtF7:hover {
  background-color: #fff;
}

.GuestcardContactForm_autofill-button__TEtF7 .MuiTouchRipple-root {
  display: none;
}

h3.GuestcardContactForm_dialog-title__1uEEU {
  font-size: 17px;
  font-family: "Roboto";
  font-weight: 500;
  line-height: 1.6;
  letter-spacing: 0.0075em;
  margin: 0px;
}

.GuestcardContactForm_dialog-label__1VSMw {
  font-family: 'Roboto';
  color: #6B738B;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  margin: 0px;
  letter-spacing: 0.5px;
}

.GuestcardContactForm_dialog-data__1bGK- h6 {
  font-family: 'Roboto';
  font-style: normal;
  color: #313131;
  font-size: 16px;
  font-weight: 600;
  padding: 5px 0;
}

.GuestcardContactForm_dialog-footer__1TDtU {
  padding: 4px 16px;
}

.GuestcardContactForm_select-dropdown__1juah .MuiInputLabel-formControl {
  -webkit-transform: translate(14px, -10px) scale(0.90);
          transform: translate(14px, -10px) scale(0.90);
  padding: 4px 10px 0px 5px;
}

.GuestcardContactForm_error-label__IPMRM .masked-phone-number input {
  margin: 0px !important;
}

.GuestcardContactForm_popup-select-box__3L1xf{
  margin-top:30px;
}
.GuestcardContactForm_popup-select-box__3L1xf .MuiInputLabel-formControl {
    -webkit-transform: translate(16px, -6px) scale(1);
            transform: translate(16px, -6px) scale(1);
    font-size: 14px;
}
.GuestcardContactForm_popup-select-box__3L1xf .MuiAutocomplete-root .MuiFormControl-root .MuiInputBase-root input{
  padding: 0px;
}
.GuestcardContactForm_popup-select-box__3L1xf .MuiAutocomplete-root .MuiFormControl-root .MuiInputBase-root .MuiAutocomplete-endAdornment button.MuiAutocomplete-popupIndicator i {
  font-size: 14px;
  padding: 6px 6px;
}

.GuestcardContactForm_popup-select-box__3L1xf .MuiAutocomplete-root .MuiFormControl-root .MuiInputBase-root .MuiAutocomplete-endAdornment button.MuiAutocomplete-popupIndicatorOpen i{
  font-size: 14px;
  padding: 3px 3px;
}

.GuestcardContactForm_popup-select-box__3L1xf .MuiInputBase-root:after{
  display: none;
}

.MuiAutocomplete-listbox li a{
  float: right;
  text-decoration: none;
  color: #0d39db;
}

.GuestcardContactForm_guestcard-popup__3Z5jJ .MuiDialogContent-root{
  padding-top: 0px;
}

.GuestcardContactForm_guestcard-popup__3Z5jJ .MuiDialogActions-spacing{
  padding: 8px 24px 18px;
}

.GuestcardContactForm_guestcard-popup__3Z5jJ p{
  font-size: 14px;
}

.GuestcardContactForm_guestcard-popup__3Z5jJ a{
  color: #0d39db;
  padding: 0px 4px;
}

.GuestcardContactForm_guestcard-popup__3Z5jJ .GuestcardContactForm_guestcard-text-opps__1fZOe p{
  color: #313131;
  font-weight: 600 !important;
}


@media only screen and (max-width: 414px) and (min-width: 320px) {
  .GuestcardContactForm_guestcard-contact-form__a_-S4 {
    width: calc(100% - 42px);
    padding: 42px 21px 0px;
    margin-top: 10px;
  }

  .GuestcardContactForm_submit-butn__3ab9x,
  .GuestcardContactForm_submit-butn__3ab9x:hover,
  .GuestcardContactForm_submit-butn__3ab9x:focus {
    font-size: 14px;
    padding: 8px 32px;
  }

  .GuestcardContactForm_total-div__2qkda {
    margin-bottom: 0px;
  }
}

.ApplicantInfo_pdf_wrapper__NAEBG {
  margin: 20px;
  border: 1px solid #ddd;
}

.ApplicantInfo_applicant_info__20VA3, .ApplicantInfo_addresses__1rscN, .ApplicantInfo_employers__1wF23, .ApplicantInfo_other_incomes__3d6tu, .ApplicantInfo_pets__gOrvu, .ApplicantInfo_vehicles__ACZIc, .ApplicantInfo_references__3RfNt, .ApplicantInfo_emergency_contacts__14CGL, .ApplicantInfo_signature_box__3JvJW {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin: 20px;
}

.ApplicantInfo_heading__2u_kr {
  width: 100%;
}

.ApplicantInfo_field__1sUn8 {
  width: 30%;
  margin-bottom: 20px;
}

.ApplicantInfo_label__3_1ok {
  font-family: 'Roboto', sans-serif;
  color: #6B738B;
  font-size: 12px;
  background-color: #fff;
  letter-spacing: 0.5px;
  margin-bottom: 5px;
}

.ApplicantInfo_info__1qQbD {
  font-family: 'Roboto', sans-serif;
  color: #313131;
  font-size: 14px;
  font-weight: 500;
  word-wrap: break-word;
  padding-right: 15px;
}

.ApplicantInfo_signature_content__3G2Fr {
  width: 100%;
  font-size: 14px;
  color: #112268;
}

.ApplicantInfo_divider__2zz4H {
  border: none;
  border-bottom: 1px solid #ddd;
}
/* Absolute Center Spinner */

.suspense-loading {
    position: fixed;
    z-index: 999;
    height: 2em;
    width: 2em;
    overflow: show;
    margin: auto;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
}

/* Transparent Overlay */

.suspense-loading:before {
    content: '';
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    /* background: radial-gradient(rgba(107 72 255, .8), rgba(169 148 255, .8));
    background: -webkit-radial-gradient(rgb(107 72 255), rgb(169 148 255)); */
}

/* :not(:required) hides these rules from IE9 and below */

.suspense-loading:not(:required) {
    /* hide "loading..." text */
    font: 0/0 a;
    color: transparent;
    text-shadow: none;
    background-color: transparent;
    border: 0;
}

.suspense-loading:not(:required):after {
    content: '';
    display: block;
    font-size: 10px;
    width: 1em;
    height: 1em;
    margin-top: -0.5em;
    -webkit-animation: spinner 2000ms infinite linear;
    animation: spinner 2000ms infinite linear;
    border-radius: 0.5em;
    box-shadow: rgba(107, 72, 255, 0.75) 1.5em 0 0 0, rgba(107, 72, 255, 0.75) 1.1em 1.1em 0 0, rgba(107, 72, 255, 0.75) 0 1.5em 0 0, rgba(107, 72, 255, 0.75) -1.1em 1.1em 0 0, rgba(107, 72, 255, 0.75) -1.5em 0 0 0, rgba(107, 72, 255, 0.75) -1.1em -1.1em 0 0, rgba(107, 72, 255, 0.75) 0 -1.5em 0 0, rgba(107, 72, 255, 0.75) 1.1em -1.1em 0 0;
}

/* Animation */

@-webkit-keyframes spinner {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@keyframes spinner {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}
