.invalid-unit {
  width: 50%;
  margin: 50px auto 0px;
  text-align: center;
  padding: 16px;
  background-color: #ffffff;
  border-radius: 6px;
  color: #606060;
  letter-spacing: 0.5px;
  font-weight: 400;
  border: 1px solid #f7f6f6;
}

.guestcard-bg {
  background-color: #E5E5E5;
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0px;
  left: 0px;
  z-index: -1;
}

.guestcard-wrap {
  width: 80%;
  margin: 65px auto;
  background-color: #fff;
  z-index: 1;
  padding-bottom: 42px;
}

.preappform-contact-form {
  padding: 24px 42px 0px 42px;
}

.preappform-contact-form h2 {
  padding: 0px;
}


/*Tab Menu*/
.tab-wrap {
  width: calc(100% - 84px);
  height: 65px;
  padding: 15px 0px 0px;
  margin: 0px auto;
  background-color: #fff;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.tab-wrap :global .MuiTabs-root {
  display: inline-block;
  height: auto;
  min-height: inherit;
}

.tabs-indicator a {
  font-size: 18px;
  color: #8E93AF;
  font-weight: 500;
  border-bottom: 0px;
  border-left: 0px;
  border-top: 0px;
  text-decoration: none;
  padding: 12px 18px 12px 18px;
  max-width: inherit;
  letter-spacing: 0.5px;
  position: relative;
  overflow: inherit;
  opacity: 1;
}

.tabs-indicator a:hover {
  text-decoration: none;
}

.tabs-indicator a:first-child {
  margin-right: 60px;
  padding: 12px 18px 12px 40px;
}

.tabs-indicator a:first-child::before {
  content: '1';
  display: block;
  width: 30px;
  height: 30px;
  background-color: #8E93AF;
  position: absolute;
  left: 0px;
  top: 12x;
  border-radius: 90px;
  color: #fff;
  opacity: 0.7;
}

.tabs-indicator a:first-child::after {
  content: '';
  display: block;
  width: 60px;
  height: 1px;
  background-color: #c9c9c9;
  position: absolute;
  right: -60px;
  top: 28px;
}

.tabs-indicator a:nth-child(2) {
  margin-right: 80px;
  padding: 12px 18px 12px 60px;
}

.tabs-indicator a:nth-child(2)::before {
  content: '2';
  display: block;
  width: 30px;
  height: 30px;
  background-color: #8E93AF;
  position: absolute;
  left: 20px;
  top: 12px;
  border-radius: 90px;
  color: #fff;
  opacity: 0.7;
}

.tabs-indicator a :global .MuiTouchRipple-root {
  display: none;
}

.tabs-indicator :global a.Mui-selected {
  color: #313131;
}

.tabs-indicator :global a.Mui-selected:first-child::before,
.tabs-indicator :global a.Mui-selected:nth-child(2)::before {
  background-color: #313131;
}

.tabs-indicator :global .MuiTabs-indicator {
  display: none;
}

.autofill-butn {
  background-color: transparent;
  border: 1px solid var(--primary);
  color: var(--primary) !important;
  font-size: 14px;
  float: right;
  margin: 0px 0px 0px 0px;
  padding: 0px 34px;
  line-height: 42px;
  text-shadow: none;
}

.autofill-butn:hover,
.autofill-butn:focus {
  background-color: transparent;
  border: 1px solid var(--primary);
  box-shadow: none;
  color: var(--primary);
}

.autofill-butn :global .MuiTouchRipple-root {
  display: none;
}

.tab-wrap .back-butn {
  background-color: transparent;
  border: 1px solid #E3E5F1 !important;
  color: #606060;
  font-size: 14px;
  float: right;
  margin: 0px 30px 0px 30px;
  padding: 8px 40px;
}

.tab-wrap .back-butn :global .MuiTouchRipple-root {
  display: none;
}

.submit-butn,
.submit-butn:hover,
.submit-butn:focus {
  background-color: #313131;
  border-color: #313131;
  font-size: 16px;
  font-weight: 500;
  padding: 10px 34px;
}

.property-title {
  position: relative;
  font-size: 22px !important;
  padding: 0px !important;
}

.unit-label {
  font-size: 14px;
  color: var(--tertiary);
}

.divide {
  color: var(--tertiary);
}

.property-container {
  padding-top: 25px;
}

.property-title::before {
  content: "\38";
  font-family: 'dashboard-icons';
  font-size: 17px;
  color: var(--tertiary);
  position: absolute;
  top: 3px;
  left: -26px;
  bottom: 0;
  vertical-align: middle;
  text-align: center;
  pointer-events: none;
}


@media only screen and (max-width: 912px) {
  .tab-wrap {
    display: block;
  }

  .autofill-butn {
    float: left;
    margin-bottom: 20px;
  }

  .form-title {
    font-size: 22px !important;
  }

}

@media only screen and (max-width: 414px) and (min-width: 320px) {
  .guestcard-wrap {
    width: 95%;
    margin: 10px auto;
  }

  .submit-butn,
  .submit-butn:hover,
  .submit-butn:focus {
    font-size: 14px;
    padding: 8px 32px;
  }

  .tab-wrap {
    display: block;
    width: calc(100% - 40px);
  }

  .tabs-indicator a:first-child {
    width: 100%;
  }

  .tabs-indicator a:first-child::after {
    display: none;
  }

  .tabs-indicator a:first-child span {
    text-align: left;
    font-size: 16px;
    align-items: flex-start;
  }

  .tabs-indicator a:nth-child(2) {
    display: block;
    width: 100%;
    padding-left: 40px;
  }

  .tabs-indicator a:nth-child(2) span {
    text-align: left;
    font-size: 16px;
    align-items: flex-start;
  }

  .tabs-indicator a:nth-child(2)::before {
    left: 0px;
  }

  .tabs-indicator :global .MuiTabs-flexContainer {
    display: block;
  }

  .tab-wrap :global .MuiTabs-root {
    display: block;
  }

  .autofill-butn {
    float: left;
    padding: 0px 30px;
    line-height: 38px;
    margin-bottom: 20px;
  }

  .guestcard-wrap :global .MuiFormControl-root {
    margin-bottom: 10px;
  }

  .form-title {
    font-size: 20px !important;
  }

  .preappform-contact-form {
    padding-left: 21px;
    padding-right: 21px;
  }

  .property-container {
    padding-left: 20px;
  }

}