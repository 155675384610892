.body {
  font-family: Arial, Helvetica, sans-serif;
  font-weight: normal;
  text-align: left;
  color: #000;
  background-color: #fff;
  padding: 0px;
  margin: 40px 40px;
}



.container {
  width: 100%;
  padding: 0px 0px 40px;
  margin: 0px;
}

.container:after {
  content: '';
  display: block;
  clear: both;
  height: auto;
}

.container h1 {
  background-color: #555555;
  padding: 15px;
  font-size: 18px;
  color: #fff;
  text-align: left;
  font-weight: 400;
  margin: 0px 0px 40px;
}

.content {
  width: 96%;
  padding: 0px;
  margin: 0px auto;
}

.content h1 {
  width: 100%;
  font-size: 24px;
  font-weight: bold;
  text-align: center;
  color: #000;
  padding: 0px 0px 15px;
  margin: 0px 0px 15px;
  background-color: transparent;
}

.content h2 {
  font-size: 16px;
  font-weight: bold;
  text-align: left;
  color: #333;
  padding: 0px 0px 15px;
  margin: 0px 0px 15px;
  border-bottom: 1px solid #ccc;
}

.content h2:last-child {
  padding: 0px 0px 0px;
  margin: 0px 0px 0px;
  border-bottom: 0px solid #ccc;
}

.content h3 {
  font-size: 16px;
  font-weight: bold;
  text-align: center;
  color: #333;
  padding: 0px 0px 15px;
  margin: 0px 0px 15px;
}

.content p {
  font-size: 14px;
  font-weight: normal;
  text-align: left;
  color: #333;
  padding: 0px 0px 15px;
  margin: 0px 0px 0px;
  line-height: 24px
}

.content p a {
  color: #333;
}

.content ul {
  width: auto;
  padding: 0px 0px 0px 40px;
  margin: 0px;
}

.content ul li {
  font-size: 14px;
  font-weight: normal;
  text-align: left;
  color: #333;
  padding: 0px 0px 15px;
  margin: 0px 0px 0px;
  line-height: 24px
}

.content ul li a {
  color: #333;
}

.content ul li ul {
  padding: 20px 0px 20px 40px;
}

.content ul li ul li {
  list-style: disc;
}

.content p.align-center {
  text-align: center;
}

.content .padding-top {
  padding-top: 60px;
}

.content table {
  width: 100%;
  border-collapse: collapse;
}

.content table tbody tr td {
  width: 50%;
  border: 1px solid #000;
  padding: 15px;
  font-size: 14px;
  font-weight: normal;
  color: #333;
  line-height: 18px;
  vertical-align: top;
}

.content table tbody tr td ol {
  padding: 0px 0px 0px 30px;
  margin: 0px;
  display: inline-grid;
}

.content table tbody tr td ol li {
  padding-bottom: 15px;
}


.checkbox {
  width: 100%;
  padding-top: 15px;
  float: left;
}

.checkbox input {
  width: 24px;
  height: 24px;
  float: left;
}

.checkbox p {
  line-height: 32px;
  padding-left: 42px;
  padding-bottom: 0px
}

.checkbox p.gray-text {
  font-size: 12px;
  color: #999;
}

/* SIGNATURE_CSS_COPIED_FROM_L2L_INDEX_CSS */
/* signature */
.signature-main table {
  width: 100%;
}

.signature-main table tbody tr {
  justify-content: space-between;
}

.signature-main table tbody tr td {
  width: 46%;
  margin: 0% 1%;
  display: inline-block;
}

.signature-main table tbody tr td p {
  font-weight: 600;
  font-size: 14px;
  color: #333;
}

.signature-main table tbody tr td p span {
  font-weight: 400;
  font-size: 13px;
  color: #666;
  padding-left: 6px;
}

.signature-area {
  border: 1px solid #ccc;
  padding: 15px;
  margin: 15px 0px;
  /* min-height: 156px; */
}

.signature-area:after {
  clear: both;
  content: '';
  display: block;
  height: auto;
}

.signature-box {
  width: auto;
  float: right;
  padding: 15px 30px 15px 0px;
  margin: 0px;
  font-size: 14px;
  text-align: left;
  color: #112268;
}

.signature-after-exam {
  width: 100%;
  float: left;
  padding: 0px;
  margin: 0px;
  font-size: 14px;
  text-align: left;
  color: #112268;
}

.signature-container {
  padding: 0px 8px;
}

.signature-container h1 {
  padding: 0px;
  margin: 0px;
}

.signature-container h2 {
  padding: 0;
  margin-top: 30px;
}

.signature-line {
  width: 96%;
  margin: 0px auto 15px;
  border-bottom: 1px solid #ccc;
  padding-top: 60px;
  padding-bottom: 0px;
  color: #ccc;
}

.signature-font {
  background-color: initial !important;
  color: blue !important;
  font-size: 30px !important;
  position: relative !important;
  text-align: left !important;
}

.submit-btn-container {
  text-align: center;
}

.signature-note {
  font-size: 11px;
  padding-top: 15px;
  color: var(--tertiary)
}

.after-exam-con {
  margin-left: 42px;
  margin-bottom: 0px;
}

.signature-after-exam.disable-esign {
  cursor: not-allowed;
}

.disable-esign .signature-line {
  pointer-events: none;
}

/* SIGNATURE_CSS_COPIED_FROM_L2L_INDEX_CSS */


@media screen and (max-width: 600px) and (min-width: 320px) {
  .signature-main table tbody tr td {
    width: 98%;
    margin: 0% 1%;
    display: inline-block;
  }
}