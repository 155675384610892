.login-main {
  width: 400px;
  text-align: center;
}

.login-main :global .MuiContainer-root {
  background-color: #fff;
  border-radius: 4px;
  box-shadow: 0px 2px 0.44px 0.36px rgba(0, 0, 0, 0.05);
  padding: 30px;
}

.login-wrap {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 95vh;
}

.login-logo {
  width: 168px;
  margin: 30px auto;
}

.login-logo img {
  object-fit: contain;
  object-position: center;
  width: 100%;
  min-height: 70px;
  max-height: 120px;
  height: auto;
  /* height:120px; */
}

.login-main h1 {
  font-size: 20px;
  padding-bottom: 30px;
  color: rgba(0, 0, 0, 0.87);
}
.login-main h1:hover {
  text-decoration: none;
}

.login-main :global .form-wrap .MuiFormControl-root {
  margin-bottom: 15px;
}

.login-with-social {
  /* padding-top: 30px; */
  margin-top: 30px;
  /* border-top: 1px solid #e7ecf2; */
  position: relative;
}

.login-with-social :global .MuiGrid-item {
  width: auto;
  float: left;
  display: contents;
}

/* 
.login-with-social::before {
  background-color: #fff;
  font-size: 14px;
  color: #7f878e;
  content: "or";
  display: block;
  clear: both;
  width: 20px;
  position: absolute;
  top: -12px;
  left: 50%;
  margin-left: -10px;
} */

:global .MuiGrid-root :local .login-with-google {
  background-color: #4285f4;
  border: 1px solid #2d7df5;
  padding: 6px 15px 6px 40px;
  width: 170px;
  margin: 0 auto;
  position: relative;
  font-family: "Source Sans Pro", sans-serif;
  font-size: 13px;
  color: #fff;
  font-weight: 400;
  text-align: center;
  letter-spacing: .5px;
  border-radius: 90px;
}

:global .MuiGrid-root :local .login-with-google:hover,
:global .MuiGrid-root :local .login-with-google:focus {
  background-color: #4285f4;
  border: 1px solid #2d7df5;
}

.login-with-google:before {
  background: url("/images/google-icon.png");
  background-repeat: no-repeat;
  background-position: 5px 6px;
  content: '';
  display: block;
  width: 18px;
  height: 18px;
  position: absolute;
  left: 3px;
  top: 2px;
  background-color: #fff;
  padding: 15px;
  border-radius: 90px;
  text-align: center;
}

.login-with-facebook:before {
  background: url("/images/fb-icon.png");
  background-repeat: no-repeat;
  background-position: 10px 6px;
  content: '';
  display: block;
  width: 18px;
  height: 18px;
  position: absolute;
  left: 3px;
  top: 3px;
  background-color: #fff;
  padding: 15px;
  border-radius: 90px;
  text-align: center;
}

.login-with-facebook {
  background-color: #415dae;
  border: 1px solid #415dae;
  padding: 7px 10px 8px 38px;
  width: 170px;
  margin: 0 auto;
  position: relative;
  font-family: "Source Sans Pro", sans-serif;
  font-size: 13px;
  color: #fff;
  font-weight: 400;
  text-align: center;
  letter-spacing: .5px;
  border-radius: 90px;
}

.login-with-facebook span {
  font-size: 12px;
}

.login-with-facebook:hover,
.login-with-facebook:focus {
  background-color: #415dae;
  border: 1px solid #415dae;
}

.forgot-password {
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 14px;
  font-weight: 400;
  color: var(--tertiary);
  text-align: left;
  text-decoration: none;
  float: left;
  line-height: 65px;
}

.forgot-password:hover {
  text-decoration: none;
}

.applicant-application {
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 14px;
  font-weight: 400;
  color: #6e8496;
  text-align: left;
  text-decoration: none;
  float: left;
}
.applicant-application:hover {
  text-decoration: none;
}

.divided-line {
  margin-top: 15px;
  border-top:1px solid #d8dae4;
  position: relative;
}
/* .divided-line::before {
  background-color: #fff;
  font-size: 14px;
  color: #7f878e;
  content: "";
  display: block;
  clear: both;
  width: 20px;
  position: absolute;
  top: -12px;
  left: 50%;
  margin-left: -10px;
} */

.last-section :global .MuiGrid-item:nth-child(1) {
  position: relative;
}
.last-section :global .MuiGrid-item:nth-child(1)::after {
  content: '';
  width: 1px;
  height: 20px;
  background: #d8dae4;
  position: absolute;
  right: 0;
  bottom: -5px;
}

.creat-an-acc {
  float: none;
  font-size: 14px;
  font-weight: 700;
  color: var(--primary);
  margin-top: 24px;
  display: block;
  padding: 5px 0px 5px;
  line-height: 0;
  width: 100%;
  text-align: center;
}

.applicant-login {
  /* float: right; */
  font-size: 14px;
  font-weight: 700;
  color: #0d3f67;
  margin-top: 24px;
  display: block;
  padding: 5px 0px 5px;
  line-height: 0;
  width: 100%;
  text-align: center;
}

.password-icon i {
  font-size: 20px;
  margin: 8px 0px 0px;
}

.verify-email-butn {
  display: block;
}

.login-main :global .MuiFormHelperText-root.Mui-error {
  position: relative;
  bottom: 0px;
}

.login-with-or {
  margin-top: 15px;
  border-top: 1px solid #d8dae4;
  position: relative;
}

.login-with-or::before {
  background-color: #fff;
  font-size: 14px;
  color: #7f878e;
  content: "or";
  display: block;
  clear: both;
  width: 20px;
  position: absolute;
  top: -12px;
  left: 50%;
  margin-left: -10px;
}

.login-with-or::after {
  content: "";
  display: block;
  clear: both;
  height: auto;
}

.property-ids :global .MuiInputBase-root {
  padding: 3px 0px 3px 8px !important;
}

@media only screen and (max-width: 600px) and (min-width: 320px) {
  .login-main {
    width: 90%;
  }

  .login-wrap {
    height: auto;
    margin-bottom: 30px;
  }

  .login-main h1 {
    padding: 15px 0px;
  }

  .login-main :global .MuiContainer-root {
    padding: 10px 10px 30px;
  }

  .login-with-facebook {
    margin-bottom: 10px;
  }

  .login-main .mb-forgot-password {
    line-height: 65px;
    text-align: center;
    width: 100%;
  }
}