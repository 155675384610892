@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
.static-page {
  height: 100%;
  border-radius: 4px;
  background-color: rgb(255, 255, 255);
  box-shadow: 1.5px 2.598px 4px 0px rgba(0, 28, 61, 0.01);
  padding: 45px 40px 32px;
  margin: 40px 40px;
  position: relative;
}

.static-page h1, .static-page h2, .static-page h3, .static-page h4 {
  font-family: 'Roboto', sans-serif;
  letter-spacing: 0.5px;
  padding: 0px 0px 0px;
  margin: 20px 0px 20px;
}

.static-page h1 {
  font-size: 18px;
  font-weight: 500;
  color: #344750;
}

.static-page h2 {
  font-size: 24px;
  font-weight: 600;
  color: #112268;
}

.static-page h3 {
  font-size: 24px;
  font-weight: 500;
  color: #112268;
}

.static-page h3 span {
  font-weight: 600;
  color: #21c4d3;
}

.static-page h4 {
  font-size: 14px;
  font-weight: 600;
  color: #344750;
}

.static-page p {
  font-size: 15px;
  color: #677982;
  font-weight: 400;
  text-align: left;
  letter-spacing: 0px;
  /*margin: 0px 0px 0px 0px;*/
  margin: 0px 0px 20px;
  line-height: 18px;
  word-break: break-all;
}

.static-page p a {
  color: #112268;
}

.static-page ol {
  width: auto;
  padding: 0px;
  margin: 0px 0px 0px 30px;
}

.static-page ol li {
  font-family: 'Roboto', sans-serif;
  font-size: 14px;
  color: #2b3f57;
  font-weight: 400;
  text-align: left;
  letter-spacing: 0px;
  margin: 0px 0px 0px 0px;
  padding: 0px !important;
  line-height: 30px;
}

.static-page ul {
  padding: 0px;
  margin: 15px 0px 30px 30px;
}

.static-page ul li {
  font-size: 14px;
  color: #2b3f57;
  font-weight: 400;
  text-align: left;
  letter-spacing: 0px;
  margin: 0px 0px 0px 0px;
  padding: 0px !important;
  line-height: 30px;
}

.add-to-home-screen-box {
  width: 90%;
  margin: 0px auto;
  border-radius: 6px;
  background: #ffffff;
  padding: 6px 12px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
  position: fixed;
  bottom: 30px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  align-items: center;
  z-index: 999;
}

.add-to-home-screen-box h1{
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 14px;
  font-weight: 400;
  color: #344750;
}

.add-to-home-screen-box h1 .share-icon {
  width: 18px;
  height: 18px;
  display: inline-block;
  margin: 2px 2px 0px;
  vertical-align: sub;
}

.add-to-home-screen-box h1 .share-icon svg{
  width: 100%;
  height: 100%;
}

.add-to-home-screen-box .close-icon {
  display: flex;
  align-items: center;
  position: relative;
  top: 1px;
  margin-left: 5px;
  margin-left: auto;
}

.add-to-home-screen-box .add-icon {
  background-color: #7f8c8c;
  border-radius: 4px;
  padding: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  min-width: 24px;
  min-height: 24px;
  margin-right: 8px;
}

.add-to-home-screen-box .add-icon svg{
  width: 100%;
  height: 100%;
}

.add-to-home-screen-box::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -10px;
  border-width: 10px;
  border-style: solid;
  border-color: white transparent transparent transparent;
}

.install-app-warraper {
  position: fixed;
  bottom: 30px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 999;
}

.install-app-warraper .install-app-btn {
  background-color: var(--primary);
  border: 1px solid var(--primary);
  color: #fff;
  width: auto;
  min-width: 145px;
  height: 40px;
  border-radius: 30px;
  font-size: 16px;
}

.install-app-warraper .install-app-btn .MuiButton-label {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.install-app-warraper .close-icon {
  color: #fff;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 16px;
  margin-top: 3px;
} 

.install-app-warraper .close-icon .icon {
  font-size: 14px;
}