.pdf_wrapper {
  margin: 20px;
  border: 1px solid #ddd;
}

.applicant_info, .addresses, .employers, .other_incomes, .pets, .vehicles, .references, .emergency_contacts, .signature_box {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin: 20px;
}

.heading {
  width: 100%;
}

.field {
  width: 30%;
  margin-bottom: 20px;
}

.label {
  font-family: 'Roboto', sans-serif;
  color: #6B738B;
  font-size: 12px;
  background-color: #fff;
  letter-spacing: 0.5px;
  margin-bottom: 5px;
}

.info {
  font-family: 'Roboto', sans-serif;
  color: #313131;
  font-size: 14px;
  font-weight: 500;
  word-wrap: break-word;
  padding-right: 15px;
}

.signature_content {
  width: 100%;
  font-size: 14px;
  color: #112268;
}

.divider {
  border: none;
  border-bottom: 1px solid #ddd;
}