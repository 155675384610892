@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap');


/*Application Form*/
.applicant-login-wrap {
  width: 80%;
  margin: 0 auto;
  position: relative;
}

.applicant-login-wrap h2 {
  font-family: 'Roboto', sans-serif;
  font-size: 22px;
  font-weight: 400;
  color: #000;
  text-align: center;
  padding-top: 15px;
  letter-spacing: 1px;
}

.applicant-login-container {
  width: 100%;
  background-color: #fff;
  border-radius: 6px;
  margin-top: 98px;
}

.applicant-login-container h1 {
  font-family: 'Roboto', sans-serif;
  font-size: 24px;
  font-weight: 600;
  color: #344750;
  text-align: center;
  padding: 20px 0px 24px;
  margin: 0px;
  width: 100%;
}

.applicant-login-container h4 {
  font-family: 'Roboto', sans-serif;
  font-size: 14px;
  font-weight: 400;
  color: #344750;
  text-align: center;
  padding: 0px 0px 40px;
  margin: 0px;
  width: 100%;
}

.applicant-login-container h5 {
  font-family: 'Roboto', sans-serif;
  font-size: 14px;
  font-weight: 400;
  color: #344750;
  text-align: center;
  padding: 0px 0px 0px;
  margin: 0px;
  width: 100%;
  line-height: 24px;
}

.applicant-login-container:after {
  content: '';
  clear: both;
  display: block;
  height: 100%;
}

.thank-you {
  position: relative;
  padding-top: 40px;
}

.thank-you::before {
  content: "\4e";
  font-family: "dashboard-icons" !important;
  background-color: #fff;
  width: 42px;
  height: 42px;
  border: 3px solid #aee448;
  padding: 12px;
  border-radius: 90px;
  color: #aee448;
  display: block;
  line-height: 14px;
  text-align: center;
}

.forgot-password {
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 14px;
  font-weight: 400;
  color: #6e8496;
  text-align: center;
  text-decoration: none;
  line-height: 14px;
}

.forgot-password:hover {
  text-decoration: none;
}

.tenant-login, .tenant-login:hover{
  padding: 8px 20px;
  background-color: #fff;
  border: 1px solid #cdd0d5d4;
  border-radius: 90px;
  font-size: 13px;
  color: #5e6a78;
  font-weight: 400;
  text-align: center;
  letter-spacing: 0.5px;
  box-shadow: none;
  line-height: 22px;
  position: relative;
  text-decoration: none;
  margin-top: 30px;
  display: inline-block;
}

@media only screen and (max-width: 1024px) {
  .applicant-login-wrap {
    width: 100%;
  }
}

@media screen and (max-width: 600px) and (min-width: 320px) {
  .applicant-login-wrap h2 {
    position: relative;
  }

  .applicant-login-container {
    width: 95%;
    margin: 10px auto 0px;
  }

  .mb-forgot-password {
    line-height: 65px;
    text-align: center;
    width: 100%;
  }
}