.must-read-con {
  text-align: left;
  color: var(--secondary);
}

.popup-top-area :global .MuiDialogTitle-root h6 {
  float: left;
  color: var(--primary);
}

.popup-top-area :global .MuiDialogTitle-root button {
  float: right;
}

.transunion_terms_of_service_checkbox {
  border-top: 1px solid #ebebf5;
  padding: 20px 0px 20px;
  margin-top: 20px;
}

.transunion_terms_of_service_checkbox :global .MuiTypography-root {
  font-size: 14px;
  text-align: left;
  color: var(--tertiary);
}

.transunion_terms_of_service_checkbox :global .MuiButtonBase-root {
  padding: 0px 15px;
  float: left;
  margin-top: -22px;
  color: var(--tertiary);
}

.transunion_terms_of_service_checkbox :global .MuiCheckbox-colorPrimary.Mui-checked {
  color: var(--primary);
}

.btn_accept, .MuiButton-contained.Mui-disabled {
  width: auto !important;
  margin: 10px auto;
  padding: 8px 40px;
}