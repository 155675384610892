@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Hurricane&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Satisfy&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Caveat&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Vujahday+Script&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Homemade+Apple&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Cedarville+Cursive&display=swap');


/*Dialog Box Size*/
.signature-popup :global .MuiDialog-paper {
  max-width: 800px !important;
  width: 800px;
}

/** dialog **/
.must-read-con {
  text-align: left;
}

.popup-top-area :global .MuiDialogTitle-root h6 {
  float: left;
  font-size: 26px;
  font-weight: 600;
}

.popup-top-area :global .MuiDialogTitle-root button {
  float: right;
}

.transunion_terms_of_service_checkbox {
  border-top: 1px solid #ebebf5;
  padding: 20px 0px 20px;
  margin-top: 20px;
}

.transunion_terms_of_service_checkbox :global .MuiTypography-root {
  font-size: 14px;
  text-align: left;
}

.transunion_terms_of_service_checkbox :global .MuiButtonBase-root {
  padding: 0px 15px;
  float: left;
  margin-top: -22px;
}

.btn_accept,
.MuiButton-contained.Mui-disabled {
  width: auto !important;
  margin: 10px 0px;
  padding: 8px 40px;
}

/** dialog **/

/*section*/
section {
  width: 100%;
  padding: 0px;
  margin: 2% 0%;
}

.container {
  width: 100%;
  padding: 0px 0px 40px;
  margin: 0px;
}

.container:after {
  content: '';
  display: block;
  clear: both;
  height: auto;
}

.container h1 {
  padding: 15px;
  font-size: 18px;
  color: #fff;
  text-align: left;
  font-weight: 400;
  margin: 0px 0px 0px;
}

.content {
  /* width: 900px; */
  padding: 0px;
  margin: 0px auto;
}

.content h1 {
  width: 100%;
  font-size: 26px;
  font-weight: 700;
  text-align: left;
  color: #000;
  padding: 0px 0px 0px;
  margin: 0px 0px 0px;
}

.content h2 {
  font-size: 16px;
  font-weight: bold;
  text-align: left;
  color: #333;
  padding: 0px 0px 15px;
  margin: 0px 0px 15px;
}

.content h2:last-child {
  padding: 0px 0px 0px;
  margin: 0px 0px 0px;
  border-bottom: 0px solid #ccc;
}

.content h3 {
  font-size: 16px;
  font-weight: bold;
  text-align: center;
  color: #333;
  padding: 0px 0px 15px;
  margin: 0px 0px 15px;
}

.content p {
  font-size: 16px;
  font-weight: normal;
  text-align: left;
  color: #6c6c6c;
  padding: 0px 0px 0px;
  margin: 0px 0px 0px;
  line-height: 18px
}

.tab-menu {
  width: 100%;
  padding: 0px 0px;
  margin: 30px 0px 0px;
  border-bottom: 1px solid #e2e2e2;
}

.tab-menu li {
  width: auto;
  padding: 0px 0px;
  margin: 0px;
  display: inline-block;
  list-style: none;
}

.tab-menu li a {
  width: auto;
  padding: 16px 72px;
  margin: 0px;
  display: block;
  font-weight: 500;
  font-size: 20px;
  text-align: center;
  color: #3d3d3d;
  text-decoration: none;
  letter-spacing: 1px;
  border-bottom: 3px solid #fff;
}

.tab-menu li a:hover,
.tab-menu li a:focus,
.tab-menu li a:active,
.tab-menu li a.active {
  color: var(--primary);
  border-bottom: 3px solid var(--primary);
}

.tab-content>div {
  display: none;
}

.tab-content .active {
  display: block;
}

.tab-type p {
  font-weight: 400;
  font-size: 16px;
  color: #7f7f7f;
  text-align: left;
  padding: 24px 0px 15px 5px;
  margin: 0px 0px;
  letter-spacing: 0.5px;
}

.tab-type button {
  font-weight: normal;
  font-size: 28px;
  color: #404040;
  border-radius: 0px;
  background-color: #fff;
  border: 1px solid #e2e2e2;
  padding: 0px 60px;
  margin: 5px 5px;
  cursor: pointer;
  width: 48%;
  min-height: 100px;
  float: left;
}


/* refer fontFamilies variable if modifying */
.font0 {
  font-family: 'Hurricane', cursive;
}

.font1 {
  font-family: 'Satisfy', cursive;
}

.font2 {
  font-family: 'Caveat', cursive;
}

.font3 {
  font-family: 'Homemade Apple', cursive;
}

.font4 {
  font-family: 'Vujahday Script', cursive;
}

.font5 {
  font-family: 'Cedarville Cursive', cursive;
}

.tab-type button:hover,
.tab-type button:focus,
.tab-type button:active,
.tab-type button.active {
  color: var(--primary);
  border-radius: 0px;
  background-color: #f1efff;
  border: 1px solid var(--primary);
}

.add-signature {
  width: 100%;
  padding: 0px;
  margin: 42px 0px 0px;
  text-align: right;
}

.add-signature button.add {
  font-family: 'Roboto', sans-serif;
  font-weight: 500;
  font-size: 18px;
  color: #fff;
  border-radius: 4px;
  background-color: #236cae;
  border: 1px solid #236cae;
  padding: 16px 74px;
  margin: 5px 5px;
  cursor: pointer;
  letter-spacing: 1px;
}

.add-signature button.add:hover,
.add-signature button.add:focus,
.add-signature button.add:active,
.add-signature button.add.active {
  color: #fff;
  border-radius: 4px;
  background-color: #236cae;
  border: 1px solid #236cae;
}

.cancel {
  font-family: 'Roboto', sans-serif;
  font-weight: 500;
  font-size: 16px;
  color: #74787b;
  border-radius: 4px;
  background-color: #fff;
  border: 1px solid #fff;
  padding: 16px 42px;
  margin: 5px 5px;
  cursor: pointer;
  letter-spacing: 1px;
  box-shadow: none;
}

.cancel:hover,
.cancel:focus,
.cancel:active,
.cancel.active {
  color: #74787b;
  border-radius: 4px;
  background-color: #fff;
  border: 1px solid #fff;
  box-shadow: none;
}

.cancel :global .MuiTouchRipple-root {
  display: none;
}

.tab-draw .signature-box {
  position: relative;
  background-color: #fff;
  border-radius: 4px;
  border: 1px solid #e0e0e0;
  /* padding: 24px; */
  margin-top: 0px;
  height: auto;
}


.tab-draw .signature-box button {
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
  font-size: 16px;
  color: #236cae;
  border-radius: 4px;
  background-color: #fff;
  border: 1px solid #aacee0;
  padding: 12px 20px;
  margin: 0px;
  cursor: pointer;
  letter-spacing: 1px;
  float: right;
}

.tab-draw .signature-box button:hover,
.tab-draw .signature-box button:focus,
.tab-draw .signature-box button:active,
.tab-draw .signature-box button.active {
  color: #236cae;
  border-radius: 4px;
  background-color: #fff;
  border: 1px solid #aacee0;
}

.tab-draw .signature-box .signature canvas {
  font-weight: 400;
  font-size: 25px;
  color: #626262;
  width: 100%;
  text-align: center;
}

.tab-draw p {
  font-weight: 400;
  font-size: 16px;
  color: #7f7f7f;
  text-align: left;
  padding: 24px 0px 15px 5px;
  margin: 0px 0px;
  letter-spacing: 0.5px;
}

.canvas-cls {
  /* for test */
  border: 1px solid red;
}

.canvas-clear {
  position: absolute;
  right: 5px;
  top: 5px;
}

.signature-popup :global .MuiDialogActions-root {
  padding: 0px 42px 15px;
}

@media only screen and (max-width: 600px) and (min-width: 320px) {
  .popup-top-area .MuiDialogTitle-root h6 {
    font-size: 18px;
  }

  .tab-menu li {
    width: 100%;
  }

  .tab-menu li a {
    padding: 8px;
    font-size: 16px;
  }

  .tab-type button {
    font-weight: normal;
    font-size: 18px;
    color: #404040;
    border-radius: 0px;
    background-color: #fff;
    border: 1px solid #e2e2e2;
    padding: 0px 15px;
    margin: 5px 5px;
    cursor: pointer;
    width: 100%;
    min-height: 50px;
    float: left;
  }
}