@charset "UTF-8";
@font-face {
  font-family: "dashboard-icons";
  src: url("./fonts/dashboard-icons.eot");
  src: url("./fonts/dashboard-icons.eot?#iefix") format("embedded-opentype"), url("./fonts/dashboard-icons.woff") format("woff"), url("./fonts/dashboard-icons.ttf") format("truetype"), url("./fonts/dashboard-icons.svg#untitled-font-16") format("svg");
  font-weight: normal;
  font-style: normal;
}

[data-dashboardicon]:before {
  font-family: "dashboard-icons"!important;
  content: attr(data-dashboardicon);
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

[class^="dashboardicon-"]:before, [class*=" dashboardicon-"]:before {
  font-family: "dashboard-icons"!important;
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.dashboardicon-view:before {
  content: "\63";
}
.dashboardicon-pdf:before {
  content: "\64";
}
.dashboardicon-register:before {
  content: "\65";
}
.dashboardicon-social-wall:before {
  content: "\61";
}
.dashboardicon-warning:before {
  content: "\62";
}
.dashboardicon-unlink:before {
  content: "\66";
}
.dashboardicon-flush:before {
  content: "\67";
}
.dashboardicon-edit:before {
  content: "\68";
}
.dashboardicon-edit-1:before {
  content: "\69";
}
.dashboardicon-attachment:before {
  content: "\6a";
}
.dashboardicon-action:before {
  content: "\6b";
}
.dashboardicon-add:before {
  content: "\6c";
}
.dashboardicon-admin:before {
  content: "\6d";
}
.dashboardicon-back:before {
  content: "\6e";
}
.dashboardicon-back-2:before {
  content: "\6f";
}
.dashboardicon-maintenance-request:before {
  content: "\70";
}
.dashboardicon-calendar:before {
  content: "\71";
}
.dashboardicon-calendar-1:before {
  content: "\72";
}
.dashboardicon-card:before {
  content: "\73";
}
.dashboardicon-change-password:before {
  content: "\74";
}
.dashboardicon-check-status:before {
  content: "\75";
}
.dashboardicon-checking:before {
  content: "\76";
}
.dashboardicon-left:before {
  content: "\77";
}
.dashboardicon-invoice:before {
  content: "\78";
}
.dashboardicon-information:before {
  content: "\79";
}
.dashboardicon-grid-view:before {
  content: "\7a";
}
.dashboardicon-down:before {
  content: "\41";
}
.dashboardicon-down-1:before {
  content: "\42";
}
.dashboardicon-delete:before {
  content: "\43";
}
.dashboardicon-customer-list:before {
  content: "\44";
}
.dashboardicon-conatct:before {
  content: "\45";
}
.dashboardicon-left-1:before {
  content: "\46";
}
.dashboardicon-list-view:before {
  content: "\47";
}
.dashboardicon-archive:before {
  content: "\48";
}
.dashboardicon-mail:before {
  content: "\49";
}
.dashboardicon-minus:before {
  content: "\4a";
}
.dashboardicon-notification-list:before {
  content: "\4b";
}
.dashboardicon-pagination-1:before {
  content: "\4c";
}
.dashboardicon-pagination-2:before {
  content: "\4d";
}
.dashboardicon-payment-status:before {
  content: "\4e";
}
.dashboardicon-personal-info:before {
  content: "\4f";
}
.dashboardicon-plantlocations:before {
  content: "\50";
}
.dashboardicon-policies:before {
  content: "\51";
}
.dashboardicon-profile-dot:before {
  content: "\52";
}
.dashboardicon-programs:before {
  content: "\53";
}
.dashboardicon-purchaseorder:before {
  content: "\54";
}
.dashboardicon-queues:before {
  content: "\55";
}
.dashboardicon-quotes:before {
  content: "\56";
}
.dashboardicon-refresh:before {
  content: "\57";
}
.dashboardicon-reset:before {
  content: "\58";
}
.dashboardicon-reverse:before {
  content: "\59";
}
.dashboardicon-right:before {
  content: "\5a";
}
.dashboardicon-right-1:before {
  content: "\30";
}
.dashboardicon-role:before {
  content: "\31";
}
.dashboardicon-search:before {
  content: "\32";
}
.dashboardicon-send:before {
  content: "\33";
}
.dashboardicon-setting:before {
  content: "\34";
}
.dashboardicon-upload:before {
  content: "\35";
}
.dashboardicon-up:before {
  content: "\36";
}
.dashboardicon-up-1:before {
  content: "\37";
}
.dashboardicon-unit-id:before {
  content: "\38";
}
.dashboardicon-translation:before {
  content: "\39";
}
.dashboardicon-timesheet:before {
  content: "\21";
}
.dashboardicon-status:before {
  content: "\22";
}
.dashboardicon-filter-up:before {
  content: "\23";
}
.dashboardicon-filter-down:before {
  content: "\24";
}
.dashboardicon-filter:before {
  content: "\25";
}
.dashboardicon-settings:before {
  content: "\26";
}
.dashboardicon-lease-charge-details:before {
  content: "\27";
}
.dashboardicon-attributes:before {
  content: "\28";
}
.dashboardicon-download:before {
  content: "\29";
}
.dashboardicon-milestone:before {
  content: "\2a";
}
.dashboardicon-logout:before {
  content: "\2b";
}
.dashboardicon-import:before {
  content: "\2c";
}
.dashboardicon-download-1:before {
  content: "\2d";
}
.dashboardicon-upload-1:before {
  content: "\2e";
}
.dashboardicon-publish:before {
  content: "\2f";
}
.dashboardicon-dashboard:before {
  content: "\3a";
}
.dashboardicon-closed-programs:before {
  content: "\3b";
}
.dashboardicon-open-programs:before {
  content: "\3c";
}
.dashboardicon-po-count:before {
  content: "\3d";
}
.dashboardicon-total-quotes:before {
  content: "\3e";
}
.dashboardicon-total-amount:before {
  content: "\3f";
}
.dashboardicon-open-order:before {
  content: "\40";
}
.dashboardicon-closed-order:before {
  content: "\5b";
}
.dashboardicon-pmslog:before {
  content: "\5d";
}
.dashboardicon-labor:before {
  content: "\5e";
}
.dashboardicon-labor-1:before {
  content: "\5f";
}
.dashboardicon-disable-icon:before {
  content: "\60";
}
.dashboardicon-log-icon:before {
  content: "\7b";
}
.dashboardicon-portal:before {
  content: "\7c";
}
.dashboardicon-commercial:before {
  content: "\7d";
}
.dashboardicon-corporate:before {
  content: "\7e";
}
.dashboardicon-add-document-1:before {
  content: "\5c";
}
.dashboardicon-add-parcel:before {
  content: "\e000";
}
.dashboardicon-add-payment:before {
  content: "\e001";
}
.dashboardicon-subscription-panel:before {
  content: "\e002";
}
.dashboardicon-unsubscribe:before {
  content: "\e003";
}
.dashboardicon-resident:before {
  content: "\e004";
}
.dashboardicon-autopay-log:before {
  content: "\e005";
}
.dashboardicon-email-manage:before {
  content: "\e006";
}
.dashboardicon-mail-log:before {
  content: "\e007";
}
.dashboardicon-image:before {
  content: "\e008";
}
.dashboardicon-guest-card:before {
  content: "\e009";
}
.dashboardicon-close:before {
  content: "\e00a";
}
.dashboardicon-payment-log:before {
  content: "\e00b";
}
.dashboardicon-add-charges:before {
  content: "\e00c";
}
.dashboardicon-transactions:before {
  content: "\e00d";
}
.dashboardicon-compose-message:before {
  content: "\e00e";
}
.dashboardicon-contact-information:before {
  content: "\e010";
}
.dashboardicon-personal-information:before {
  content: "\e011";
}
.dashboardicon-add-charges-1:before {
  content: "\e012";
}
.dashboardicon-add-payment-1:before {
  content: "\e00f";
}
.dashboardicon-history:before {
  content: "\e013";
}
.dashboardicon-website-view:before {
  content: "\e014";
}
.dashboardicon-users:before {
  content: "\e015";
}
.dashboardicon-unit-icon:before {
  content: "\e016";
}
.dashboardicon-document:before {
  content: "\e017";
}
.dashboardicon-current-application:before {
  content: "\e018";
}
.dashboardicon-image-search:before {
  content: "\e019";
}