@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap');

/* if allow_edit is false then disable form edit */
.disable-form-edit {
  pointer-events: none;
}

.disable-form-edit form,
.disable-form-edit .auto-fill,
.disable-form-edit .submit-butn-main button {
  opacity: 0.7;
}

.override-disable-form-edit {
  pointer-events: auto;
  opacity: 1 !important;
}

/* if allow_edit is false then disable form edit */

.disabled{
  opacity: 0.5;
}

.login-logo {
  width: 100%;
  padding: 0 20px;
  margin: 20px 0;
  text-align: center;
}

.login-logo img {
  object-fit: contain;
  object-position: center;
  width: 100%;
}

/*Application Form*/
.application-wrap {
  margin: 0 auto;
  position: relative;
  background: #f2f3f7;
  min-height: 100vh;
}

.application-form-header {
  padding: 20px;
  background-color: #f2f3f7;
  position: sticky;
  top: 0px;
}

.logo {
  z-index: 9;
  width: 100%;
  text-align: center;
}

.application-wrap h2 {
  font-family: 'Roboto', sans-serif;
  font-size: 22px;
  font-weight: 400;
  color: #000;
  text-align: center;
  padding-top: 46px;
  letter-spacing: 1px;
}

.application-wrap h1 {
  font-family: 'Roboto', sans-serif;
  font-size: 20px;
  font-weight: 500;
  color: var(--primary);
  letter-spacing: 1px;
}

.application-contain-area {
  padding: 20px 40px 40px;
}

.application-container {
  width: 100%;
  background-color: #fff;
  border-radius: 6px;
  /* margin-top: 140px; */
}

.application-container h1 {
  font-family: 'Roboto', sans-serif;
  font-size: 24px;
  font-weight: 600;
  color: #344750;
  text-align: center;
  margin: 0px;
  width: 100%;
}

.application-container h4 {
  font-family: 'Roboto', sans-serif;
  font-size: 14px;
  font-weight: 400;
  color: #344750;
  text-align: center;
  padding: 0px 0px 40px;
  margin: 0px;
  width: 100%;
}

.please-wait {
  width: 100%;
  background-color: #fff;
  border-radius: 6px;
  position: absolute;
  top: 0px;
  z-index: -1;
}

.please-wait-con {
  width: 100%;
  text-align: center;
  font-family: 'Roboto', sans-serif;
  font-size: 14px;
  font-weight: 400;
  color: #344750;
}

.form-height {
  min-height: 100vh;
  margin-bottom: 70px;
}

.application-form main {
  padding: 0px;
}

.form-divider {
  margin-top: 30px;
}

.heading {
  margin-bottom: 24px;
}

.sub-heading {
  font-family: 'Roboto', sans-serif;
  font-size: 15px;
  color: var(--tertiary);
}

.form-grp {
  margin: 30px 40px 0px;
}

.form-grp h1 {
  font-family: 'Roboto', sans-serif;
  font-size: 18px;
  font-weight: 600;
  color: var(--primary);
  text-align: left;
  margin: 0px;
  background-color: transparent;
}

.form-grp :global .MuiFormControl-root {
  margin-bottom: 0px;
}

.spacing-bottom {
  margin-bottom: 30px !important;
}

/* for adding space of spacing={3} between 2 containers */
.container-spacing-3 {
  margin-top: 12px;
}

/* Radio Button*/
.radio-btn :global .MuiFormControl-root {
  display: block;
  margin-bottom: 0px;
}
.radio-btn :global .MuiFormLabel-root {
  font-family: 'Roboto', sans-serif;
  font-size: 15px;
  color: var(--tertiary);
  padding-right: 16px;
  float: left;
  margin-top: 4px;
}
.radio-btn :global .MuiFormControlLabel-root .MuiButtonBase-root {
  padding: 0px 4px 4px 9px;
  align-self: flex-start;
  color: var(--tertiary);
}
.radio-btn :global .MuiFormControlLabel-root .MuiButtonBase-root.Mui-checked {
  color: var(--primary);
}
.radio-btn :global .MuiFormControlLabel-root .MuiButtonBase-root svg {
  font-size: 22px;
}
.radio-btn :global .MuiFormControlLabel-root .MuiFormControlLabel-label {
  font-family: 'Roboto', sans-serif;
  font-size: 15px;
  color: var(--secondary);
  padding-bottom: 4px;
}

.chip-selected,
.chip-selected:hover {
  background-color: var(--primary) !important;
  color: #fff;
}

.chip-unselected,
.chip-unselected:hover {
  background-color: #fff;
  border: 1px solid var(--primary);
  color: var(--primary);
}

.label-text {
  color: var(--tertiary);
}

/* Checkbox */
.checkbox :global .MuiFormControlLabel-root .MuiButtonBase-root {
  padding: 0px 4px 4px 9px;
  align-self: flex-start;
  color: var(--tertiary);
}
.checkbox :global .MuiFormControlLabel-root .MuiButtonBase-root.Mui-checked {
  color: var(--primary);
}
.checkbox :global .MuiFormControlLabel-root .MuiButtonBase-root svg {
  font-size: 22px;
}
.checkbox :global .MuiFormControlLabel-root .MuiFormControlLabel-label {
  font-family: 'Roboto', sans-serif;
  font-size: 15px;
  color: var(--tertiary);
  padding-bottom: 4px;
}

/*Add more and Remove*/

.add-more-button {
  background-color: transparent;
  border: none;
  box-shadow: none;
  font-family: 'Roboto', sans-serif;
  font-size: 13px;
  font-weight: 500;
  color: var(--primary);
  padding: 0px;
}

.add-more-button:hover,
.add-more-button:focus,
.add-more-button:active {
  background-color: #fff;
  border: none;
  box-shadow: none !important;
}

.add-more-button :global .MuiTouchRipple-root {
  display: none;
}

.remove-button {
  background-color: transparent;
  border: none;
  box-shadow: none;
  font-family: 'Roboto', sans-serif;
  font-size: 13px;
  font-weight: 500;
  color: #f40d13;
  padding: 0px;
}

.remove-button:hover,
.remove-button:focus,
.remove-button:active {
  background-color: #fff;
  border: none;
  box-shadow: none !important;
}

.remove-button :global .MuiTouchRipple-root {
  display: none;
}

.submit-butn-main {
  background-color: #fff;
  position: fixed;
  bottom: 0px;
  z-index: 2;
  border-top: 1px solid rgba(0, 0, 0, 0.12);
  padding: 15px 0px;
  text-align: center;
  width: -webkit-fill-available;
}

.submit-butn-main:after {
  content: '';
  display: block;
  height: auto;
  clear: both;
}

.must-read a {
  font-family: 'Roboto', sans-serif;
  text-decoration: none;
  color: var(--primary);
  cursor: pointer;
  font-weight: 500;
  font-size: 12px;
}

.must-read a:hover {
  text-decoration: none;
}

.thank-you {
  position: relative;
  padding-top: 40px;
}

.thank-you::before {
  content: "\4e";
  font-family: "dashboard-icons" !important;
  background-color: #fff;
  width: 42px;
  height: 42px;
  border: 3px solid #aee448;
  padding: 12px;
  border-radius: 90px;
  color: #aee448;
  display: block;
  line-height: 14px;
  text-align: center;
}

/*Applicant Payment*/

.applicant-payment-bg {
  width: 720px;
  margin: 46px auto 0px;
  background-color: #fff;
  padding: 40px;
  border-radius: 6px;
  box-shadow: 2.5px 4.33px 9px 1px rgba(126, 149, 184, 0.04);
}

.content-space {
  text-align: center;
}

.coming-soon-text {
  margin-top: 10px;
}

.payment-type-box-content {
  margin-bottom: 0px;
}

.invoice-no {
  width: 100%;
  text-align: center;
  font-family: 'Roboto', sans-serif;
  font-size: 14px;
  font-weight: 400;
  color: #8e908f;
  padding: 0px 0px 0px;
}

.applicant-name {
  width: 100%;
  text-align: center;
  font-family: 'Roboto', sans-serif;
  font-size: 16px;
  font-weight: 500;
  color: #0d3f67 !important;
  letter-spacing: 0.5px;
  padding: 0px 0px 40px;
}

.application-fee {
  width: 100%;
  text-align: center;
  font-family: 'Roboto', sans-serif;
  font-size: 14px;
  font-weight: 500;
  color: #000 !important;
  letter-spacing: 0.5px;
  border-bottom: 1px solid #e0e0e0;
  padding: 0px 0px 12px;
  margin-bottom: 12px;
}

.application-amount {
  width: 100%;
  text-align: left;
  font-family: 'Roboto', sans-serif;
  font-size: 13px;
  font-weight: 400;
  color: #333 !important;
  padding: 0px 0px 12px;
  border-bottom: 1px solid #e0e0e0;
  margin-bottom: 24px;
}

.application-amount span {
  font-size: 14px;
  font-weight: 700;
  float: right;
}

.applicant-payemt-plz-wait {
  width: 80%;
  z-index: 9;
  position: absolute;
  top: 100px;
  left: 50%;
  text-align: center;
  font-family: 'Roboto', sans-serif;
  font-size: 14px;
  font-weight: 400;
  color: #344750;
  transform: translate(-50%, 0px);
}

.applicant-payemt-plz-wait :global .MuiAlert-standardInfo {
  text-align: left;
}

.auto-fill-div {
  position: absolute;
  right: 0px;
}

.auto-fill {
  color: var(--primary);
  width: auto;
  padding: 12px 24px 12px;
  text-align: center;
  font-weight: 600;
  cursor: pointer;
  border: 1px solid var(--primary);
  border-radius: 90px;
  margin-top: 15px;
  margin-right: 40px;
}

.co-applicant-list {
  margin-top: 24px;
  font-size: 12px;
}

.co-applicant-list h1 {
  margin: 0px 0px 15px;
}

.co-applicant-list-view {
  background-color: #f1f1f1;
  border-radius: 4px;
  padding: 0px 15px;
  color: #6e6e6e;
}

.co-applicant-list-view :global .MuiGrid-item {
  word-break: break-word;
}

.co-applicant-list-view :global .MuiGrid-item:last-child {
  text-align: right;
}

.hidden {
  display: none;
}

.form-grp :global .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] .MuiAutocomplete-input {
  padding: 0px !important;
}

/* Progress Info */
.progress-info {
  border-radius: 8px;
  background-color: #EDE2BC;
  margin-top: 16px;
  padding: 8px;
  color: #917243;
}

/* Payment form */
.payment-title {
  background-color: #ebf3e4;
  padding: 0px 10px;
}

.card-box {
  display: inline-block;
}

.card-box h3 {
  font-size: 18px;
  font-weight: 600;
  color: #0d3f67;
  padding: 0;
}

.card-box span {
  margin: 0 10px;
}

.autofill-button {
  font-size: 13px;
  padding: 9px 21px;
  color: var(--primary);
}

.autofill-button :global .MuiTouchRipple-root {
  display: none;
}

.state-box :global .MuiInputBase-root {
  padding: 3px 0px 3px 8px !important;
}

.radio-div {
  text-align: left;
  margin-bottom: 15px;
}

.account-type-heading :global .MuiFormLabel-root {
  padding-top: 0px;
}

.radio-div :global .MuiFormControl-root .MuiFormControlLabel-root .MuiTypography-root {
  border-radius: 90px;
  padding: 10px 20px;
}

.radio-div :global label span.MuiTypography-root {
  background-color: #faf8ff;
  color: #434345;
  font-size: 14px;
  font-weight: 400;
  border: none;
}

.radio-div :global .checked-amount-radio span.MuiTypography-root {
  /* color: #69647d;
  font-size: 14px;
  font-weight: 600;
  background-color: #d5cbff;
  border: none; */
}

.dialog-amount h6 {
  font-family: 'Roboto';
  font-style: normal;
  color: #10b983;
  font-size: 18px;
  font-weight: 500;
  padding: 5px 0;
}

h3.dialog-title {
  font-size: 17px;
  font-family: "Roboto";
  font-weight: 500;
  line-height: 1.6;
  letter-spacing: 0.0075em;
  margin: 0px;
}

.dialog-label {
  font-family: 'Roboto';
  color: #6B738B;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  margin: 0px;
  letter-spacing: 0.5px;
}

.dialog-data h6 {
  font-family: 'Roboto';
  font-style: normal;
  color: #313131;
  font-size: 16px;
  font-weight: 500;
  padding: 5px 0;
}

.dialog-footer {
  padding: 4px 16px;
}

.cancel-button {
  background-color: #fff;
  border: 1px solid var(--primary);
  border-radius: 90px;
  font-size: 14px;
  color: var(--primary);
  font-weight: 500;
  padding: 8px 20px;
}

.cancel-button:hover {
  background-color: transparent;
}

.cancel-app-btn {
  background-color: #cf142b;
  border: 1px solid #cf142b;
  border-radius: 90px;
  font-size: 14px;
  box-shadow: none;
  color: #fff !important;
  font-weight: 500;
  text-align: center;
  letter-spacing: 0.5px;
  padding: 8px 40px;
  text-shadow: -1px 1px 1px #33333357;
}

.cancel-app-btn:hover {
  background-color: #cf142b;
  box-shadow: none;
}

.progress-info svg {
  color: #caaa59;
  font-size: 16px;
}

.progress-info .info {
  font-family: 'Roboto', sans-serif;
  font-size: 11px;
}

/* Progress Bar */
.progress-bar {
  margin-top: 20px;
}

.progress-bar :global .MuiLinearProgress-colorPrimary {
  background-color: #D7D7D7;
  border-radius: 5px;
}

.progress-bar :global .MuiLinearProgress-barColorPrimary {
  background-color: #11b666;
}

.progress-bar .progress-count {
  color: #11b666;
  font-weight: 600;
  font-size: 12px;
}

.progress-bar .progress-label {
  font-size: 12px;
  color: #606060;
  font-family: 'Roboto', sans-serif;
}

/* Upload Files Section */
.upload-files-section {
  padding-top: 12px;
}

.upload-files-button {
  font-family: 'Roboto', sans-serif;
  font-size: 13px;
  color: var(--primary);
  font-weight: 500;
  background-color: #fff;
  border: 1px solid var(--primary);
  border-radius: 20px;
  padding: 5px 16px;
}

.upload-files-button:hover {
  background-color: #fff;
}

/* Uploaded Files Section */
.uploaded-file .file-view-icon svg {
  font-size: 18px;
}

.uploaded-files-section {
  margin-top: 15px;
}

.uploaded-file {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
}

.uploaded-file .file-name {
  font-size: 14px;
  color: #344750;
}

.uploaded-file .date-time {
  font-size: 12px;
  color: #888;
}

.uploaded-file .file-view-icon {
  color: var(--primary);
  padding: 4px;
  top: -1px;
}

/* Tabs */
.form-tabs-container {
  margin-top: 16px;
}

.form-tabs {
  background-color: #f9f9f9;
  margin-top: 8px;
  border-radius: 5px;
  min-height: 48px;
  padding-top: 0px;
  padding-bottom: 0px;
  color: #9499AA;
}

.form-tabs:hover {
  color: #344750;
}

.form-tabs :global .MuiTab-wrapper {
  flex-direction: row;
  justify-content: space-between;
  padding: 0px 8px;
}

.form-tabs:global.Mui-selected {
  background-color: var(--primary);
  color: #fff;
}

.form-tabs .tab-label {
  font-family: 'Roboto', sans-serif;
}

.form-tabs svg {
  color: #11b666;
}

.fixed-wrap {
  z-index: 99;
}

.fixed-div {
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  background-color: #f2f3f7;
  padding: 0px 15px !important;
  z-index: 99;
}
.year-picker label {
  transform: translate(14px, -10px) scale(0.90) !important;
  padding: 4px 20px 0px 4px;
  z-index: 1;
  background: #fff;
}
.year-picker :global .MuiInput-underline {
  border: 1px solid #e2e8ef;
  border-radius: 4px;
  margin-top: 0px;
  padding: 11px 0px 11px 15px;
}
.year-picker :global .MuiInput-underline.Mui-focused {
  border: 2px solid var(--primary);
  padding: 10px 0px 10px 15px;
}
.year-picker :global .MuiInput-underline.Mui-error {
  border: 1px solid #f44336;
}
.year-picker :global .MuiInput-underline:before, .year-picker :global .MuiInput-underline:after {
  content: none;
}
.year-picker svg {
  opacity: 0;
}
.year-picker :global .MuiIconButton-label {
  position: relative;
}
.year-picker :global .MuiIconButton-label::after {
  content: "\72";
  font-family: 'dashboard-icons';
  font-size: 16px;
  position: absolute;
  top: 4px;
  right: 4px;
  bottom: 0;
  vertical-align: middle;
  text-align: center;
  pointer-events: none;
}

@media screen and (max-width: 599px) {
  .application-wrap h2 {
    position: relative;
  }

  .application-contain-area {
    padding: 15px 15px 40px;
  }

  .remove-button {
    font-size: 12px;
  }

  .applicant-payment-bg {
    width: 100%;
    padding: 25px 15px;
  }

  .auto-fill {
    margin-right: 15px;
    padding: 8px 20px;
    font-size: 12px;
  }

  .login-logo img {
    max-width: 175px;
  }

  .form-tabs {
    background-color: transparent;
    border-radius: 0px;
    padding: 0px;
    overflow: auto;
    min-height: 40px;
  }

  .form-tabs:global.Mui-selected {
    border-bottom: 2px solid;
    background-color: transparent;
    color: var(--primary);
  }

  .form-tabs :global .MuiTab-wrapper {
    padding: 0px;
    justify-content: center;
  }

  .form-tabs .tab-label {
    font-size: 11px;
    margin-top: 10px;
    line-height: 16px;
  }

  .form-tabs svg {
    position: absolute;
    top: -4px;
    font-size: 18px;
  }

  .form-tabs-container {
    margin-top: 8px;
  }

  .form-tabs-container :global .MuiTabs-flexContainer {
    overflow: auto;
  }

  .application-form-header {
    padding: 0px 15px;
  }

  .form-grp {
    margin: 15px 15px 0px;
  }
}