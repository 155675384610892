.login-logo {
  width: 168px;
  margin: 0px auto 30px; 
}

.login-logo img {
  object-fit: contain;
  object-position: center;
  width: 100%;
  height: 70px;
}

.applicant-exam-main {
    width: 80%;
    margin: 0 auto;
    background-color: #fff;
    border-radius: 6px;
    /* margin-top: 80px; */
    padding: 0px 35px 40px;
}

.logo {
    width: 100%;
    text-align: center;
    margin: 0px auto 30px;
}

.applicant-exam-main h3 {
    font-family: 'Roboto', sans-serif;
    font-size: 24px;
    font-weight: 400;
    color: #000;
    text-align: center;
    padding: 0px 0px 40px;
    margin: 0px;
}

.applicant-exam-main h4 {
    font-family: 'Roboto', sans-serif;
    font-size: 14px;
    font-weight: 400;
    color: #8e908f;
    text-align: center;
    padding: 0px 0px 10px;
    margin: 0px;
}

.question-answer-main {
    width: 100%;
    margin: 0px;
    padding: 0px 0px 40px;
}

.question-answer-main :global label.MuiFormLabel-root {
    font-family: 'Roboto', sans-serif;
    font-size: 14px;
    font-weight: 500;
    color: #344750;
    text-align: left;
    padding: 0px 0px 10px;
    margin: 0px;
    line-height: 24px;
}

.question-answer-main :global .MuiTypography-root {
    font-family: 'Roboto', sans-serif;
    font-size: 14px;
    font-weight: 400;
    color: #8e908f;
    text-align: left;
    padding: 0px 0px 0px;
    margin: 0px;
}

.applicant_info {
    border: 1px solid #e7ecf2;
    border-radius: 6px;
    background-color: #fff;
    /* box-shadow: 2.5px 4.33px 9px 1px rgba(126, 149, 184, 0.04); */
    padding: 30px !important;
}

.detail_main {
    margin-right: 24px;
    padding-right: 24px;
    border-right: 1px solid #e7ecf2;
    float: left;
}

.detail_label {
    color: #6e8496;
    font-size: 14px;
    padding-bottom: 8px;
}

.detail_info {
    color: #0d3f67;
    font-size: 14px;
    font-weight: 600;
    text-transform: none !important;
    cursor: pointer;
}

.exam-button :global .MuiButton-root.Mui-disabled {
    opacity: 0.5;
}

@media only screen and (max-width: 600px) and (min-width: 320px) {
    .applicant-exam-main {
        width: 100%;
        padding: 0px 0px 30px;
    }
}