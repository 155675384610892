body {
  height: auto;
}

.guestcard-contact-form {
  width: calc(100% - 84px);
  padding: 42px 42px 0px;
}

.form-wrap .guestcard-wrap :global .MuiInputBase-root textarea {
  height: 20px !important;
}

.guestcard-contact-form :global input[type="date"]::-webkit-inner-spin-button,
.guestcard-contact-form :global input[type="date"]::-webkit-calendar-picker-indicator {
  font-size: 18px;
  color: var(--tertiary);
  margin-right: 8px;
  -webkit-appearance: none;
  opacity: 0.5;
  cursor: pointer;
}

.fetching {
  color: #0566f8;
  font-size: 14px;
  font-weight: 500;
}

.submit-butn,
.submit-butn:hover,
.submit-butn:focus {
  background-color: #313131;
  border-color: #313131;
  font-size: 16px;
  font-weight: 500;
  padding: 10px 34px;
}

.comment-box {
  margin-bottom: 0px;
}

.autofill-butn {
  background-color: transparent;
  border: 1px solid #c5c5c5;
  color: #313131;
  font-size: 14px;
  float: right;
  margin: 0px 0px 0px 0px;
  padding: 0px 34px;
  line-height: 42px;
}

.autofill-butn:hover,
.autofill-butn:focus {
  background-color: transparent;
  border: 1px solid #c5c5c5;
  box-shadow: none;
  color: #313131;
}

.autofill-butn :global .MuiTouchRipple-root {
  display: none;
}

.guestcard-contact-form :global .radio-butn .checked-amount-radio span.MuiTypography-root,
.guestcard-contact-form :global .radio-butn .checked-amount-radio label span.MuiTypography-root {
  background-color: #313131;
  border: 1px solid #313131;
}

.state-box :global .MuiInputBase-root {
  padding: 3px 0px 3px 8px !important;
}

.guestcard-contact-form :global input.masked-phone-number {
  width: 100%;
  display: inline-block;
  padding: 11px 0px 11px 15px;
  box-sizing: border-box;
}

.guestcard-contact-form :global .MuiFormHelperText-root.Mui-error {
  word-break: break-all;
}

.card-box {
  display: inline-block;
}

.card-box p {
  font-size: 16px;
  font-weight: 400;
  color: var(--tertiary);
}

.card-box h3 {
  font-size: 18px;
  font-weight: 600;
  color: var(--primary);
  padding: 0;
}

.card-box span {
  margin: 0 10px;
}

.equal-sign {
  color: #9a9a9a;
  font-size: 18px;
}

.total-div {
  display: flex;
  align-items: flex-end;
  margin-bottom: 23px;
}

.card-box .total-amt {
  font-size: 16px;
  font-weight: 400;
  color: var(--tertiary);
}

.card-div {
  margin-left: 10px;
}

.autofill-button {
  color: #313131;
  background-color: #fff;
}

.autofill-button:hover {
  background-color: #fff;
}

.autofill-button :global .MuiTouchRipple-root {
  display: none;
}

h3.dialog-title {
  font-size: 17px;
  font-family: "Roboto";
  font-weight: 500;
  line-height: 1.6;
  letter-spacing: 0.0075em;
  margin: 0px;
}

.dialog-label {
  font-family: 'Roboto';
  color: #6B738B;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  margin: 0px;
  letter-spacing: 0.5px;
}

.dialog-data h6 {
  font-family: 'Roboto';
  font-style: normal;
  color: #313131;
  font-size: 16px;
  font-weight: 600;
  padding: 5px 0;
}

.dialog-footer {
  padding: 4px 16px;
}

.select-dropdown :global .MuiInputLabel-formControl {
  transform: translate(14px, -10px) scale(0.90);
  padding: 4px 10px 0px 5px;
}

.error-label :global .masked-phone-number input {
  margin: 0px !important;
}

.popup-select-box{
  margin-top:30px;
}
.popup-select-box :global .MuiInputLabel-formControl {
    transform: translate(16px, -6px) scale(1);
    font-size: 14px;
}
.popup-select-box :global .MuiAutocomplete-root .MuiFormControl-root .MuiInputBase-root input{
  padding: 0px;
}
.popup-select-box :global .MuiAutocomplete-root .MuiFormControl-root .MuiInputBase-root .MuiAutocomplete-endAdornment button.MuiAutocomplete-popupIndicator i {
  font-size: 14px;
  padding: 6px 6px;
}

.popup-select-box :global .MuiAutocomplete-root .MuiFormControl-root .MuiInputBase-root .MuiAutocomplete-endAdornment button.MuiAutocomplete-popupIndicatorOpen i{
  font-size: 14px;
  padding: 3px 3px;
}

.popup-select-box :global .MuiInputBase-root:after{
  display: none;
}

:global .MuiAutocomplete-listbox li a{
  float: right;
  text-decoration: none;
  color: #0d39db;
}

.guestcard-popup :global .MuiDialogContent-root{
  padding-top: 0px;
}

.guestcard-popup :global .MuiDialogActions-spacing{
  padding: 8px 24px 18px;
}

.guestcard-popup :global p{
  font-size: 14px;
}

.guestcard-popup :global a{
  color: #0d39db;
  padding: 0px 4px;
}

.guestcard-popup :local .guestcard-text-opps p{
  color: #313131;
  font-weight: 600 !important;
}


@media only screen and (max-width: 414px) and (min-width: 320px) {
  .guestcard-contact-form {
    width: calc(100% - 42px);
    padding: 42px 21px 0px;
    margin-top: 10px;
  }

  .submit-butn,
  .submit-butn:hover,
  .submit-butn:focus {
    font-size: 14px;
    padding: 8px 32px;
  }

  .total-div {
    margin-bottom: 0px;
  }
}
